import { Icon } from '@iconify/react';
import iconUser from '@iconify-icons/heroicons/user';
import React from 'react';
import { Nullish } from '../Utils/typescript';

export function CircledUserImageComponent({
  user,
  size,
}: {
  size: string;
  user: { displayName: string; photoUrl: Nullish<string> };
}) {
  size ??= '40px';
  return (
    <div
      style={{ width: size, height: size }}
      className={
        'rounded-full overflow-hidden bg-gray-800 text-white ' +
        'focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
      }
    >
      <UserImageComponent user={user} />
    </div>
  );
}

export function UserImageComponent({ user }: { user: { displayName: string; photoUrl: Nullish<string> } }) {
  return user.photoUrl ? (
    <img
      alt={user.displayName}
      src={user.photoUrl}
      referrerPolicy='no-referrer'
      style={{ height: '100%', width: 'auto' }}
      width='100%'
      height='100%'
    />
  ) : (
    <div className='flex p-2'>
      <Icon icon={iconUser} height='100%' />
    </div>
  );
}