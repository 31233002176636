import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode, useCallback, useState } from 'react';
import { RenderFn, useImperativeApi } from '../hooks/makeImperativeApi';

function DialogWrapper<T>({
  render,
  renderActions,
  unmount,
  resolve,
  title,
  cancelResult,
}: {
  resolve: (arg: T) => void;
  unmount: () => void;
  render: RenderFn<DialogResolve<T>>;
  renderActions: RenderFn<DialogResolve<T>>;
  title: ReactNode;
  cancelResult: T;
}) {
  const [isOpen, setIsOpen] = useState(true);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const renderProps: DialogResolve<T> = {
    resolve: (result) => {
      close();
      resolve(result);
    },
  };
  return (
    <MyModal
      title={title}
      isOpen={isOpen}
      onClose={() => {
        resolve(cancelResult);
        setIsOpen(false);
        unmount();
      }}
      actions={renderActions(renderProps)}
    >
      {render(renderProps)}
    </MyModal>
  );
}

export type DialogResolve<T> = { resolve: (arg: T) => void };

export type DialogProps<T> = {
  cancelResult: T;
  title: RenderFn;
  render: RenderFn<DialogResolve<T>>;
  renderButtons?: RenderFn<DialogResolve<T>>;
};

export function useDialogApi() {
  const imperative = useImperativeApi();
  return {
    show: <T,>(props: DialogProps<T>) => {
      return new Promise<T>((resolve) => {
        imperative.add(({ key, remove }) => (
          <DialogWrapper<T>
            key={key}
            render={props.render}
            renderActions={props.renderButtons || (() => <></>)}
            resolve={resolve}
            unmount={remove}
            title={props.title()}
            cancelResult={props.cancelResult}
          />
        ));
      });
    },
  };
}

function MyModal({
  isOpen,
  onClose,
  children,
  title,
  actions,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: ReactNode;
  actions: ReactNode;
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel
                className={
                  'w-full max-w-md transform overflow-hidden rounded-2xl bg-white ' +
                  'p-6 text-left align-middle shadow-xl transition-all'
                }
              >
                <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  {title}
                </Dialog.Title>
                <div className='mt-2'>
                  <div className='text-sm text-gray-500 flex flex-col'>{children}</div>
                </div>

                <div className='mt-4'>
                  <button
                    type='button'
                    className={
                      'inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 ' +
                      'text-sm font-medium text-blue-900 hover:bg-blue-200 ' +
                      'focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                    }
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  {actions}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
