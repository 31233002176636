import { useState } from 'react';
import { useFirebaseAuth } from '../firebase';
import { CheckEmailComponent } from '../Shell/authentication/AuthenticationForm';
import { sendPasswordResetEmail } from '../Shell/authentication/firebase-helpers';
import { TextFormField } from '../UI/FormField';
import { AsyncButton } from '../Utils/controls/AsyncButton';

export function RequestPasswordResetComponent({ email: emailProp }: { email?: string }) {
  const [email, setEmail] = useState(emailProp ?? '');
  const auth = useFirebaseAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <div className='flex flex-col'>
      <TextFormField
        label='Email'
        placeholder='test@example.com'
        disabled={isLoading}
        value={email}
        onChange={setEmail}
        type='email'
      />
      <AsyncButton
        onClickAsync={async () => {
          setIsLoading(true);
          setIsSuccess(false);
          setError(undefined);
          const r = await sendPasswordResetEmail(auth, email);
          if (r.ok === true) {
            setIsSuccess(true);
          } else {
            setError('Could not send password reset link.');
          }
        }}
      >
        Send reset link
      </AsyncButton>
      {error && <div className='text-red-500 text-sm'>{error}</div>}
      {isSuccess && <CheckEmailComponent email={email} />}
    </div>
  );
}
