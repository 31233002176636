import userIcon from '@iconify-icons/heroicons/user';
import { Icon } from '@iconify/react';
import { ReactElement } from 'react';
import { WithId } from '../models/commons';
import { RsvpInfo } from '../models/event';
import { UserObjectReference } from '../models/user';

export default function UserSnippetComponent({
  user,
  rsvpInfo,
}: {
  user: WithId<UserObjectReference>;
  rsvpInfo?: RsvpInfo;
}): ReactElement {
  return (
    <div className='p-2 w-full flex justify-center '>
      <div className='w-full cursor-pointer p-1 flex flex-col md:flex-row rounded-lg bg-white shadow-lg'>
        <Icon icon={userIcon} width={200} />
        <div className='p-3 flex flex-col justify-center'>
          <h5 className='text-gray-900 text-xl font-medium'>
            {user.displayName + (rsvpInfo ? ' ( + ' + rsvpInfo?.extras + ' )' : '')}
          </h5>
          <p className='text-gray-700 text-base mb-2'>{user.description || ''}</p>
        </div>
      </div>
    </div>
  );
}
