import checkIcon from '@iconify-icons/ion/checkmark';
import { Icon } from '@iconify/react';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { StyledButton } from '../../UI/tailwind-styles';
import { FlexCenterRow } from './LayoutStyles';
import { Spinner } from './SmallSpinner';
import { useIsMounted } from './useIsMounted';
import { useSnackbar } from './useSnackbar';

const StyledSpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: opacity 200ms;
`;

const StyledContentContainer = styled.div`
  transition: opacity 200ms;
`;

export function AsyncButton({
  onClickAsync,
  children,
  hideCheck,
  isLoading,
  isSucceeded,
  disabled,
  className,
  'data-test-id': dataTest,
}: React.ButtonHTMLAttributes<HTMLButtonElement> &
  AsyncButtonProps & {
    onClickAsync: () => Promise<void | 'error'>;
    children?: ReactNode;
    hideCheck?: boolean;
    'data-test-id'?: string;
  }) {
  return (
    <AsyncButtonBase
      isLoading={isLoading}
      isSucceeded={isSucceeded}
      onClickAsync={onClickAsync}
      render={({ isLoading, isSucceeded, onClick }) => {
        const content =
          isSucceeded && !hideCheck ? (
            <FlexCenterRow>
              {children}
              <Icon data-test-id='success-marker' icon={checkIcon} />
            </FlexCenterRow>
          ) : (
            children
          );

        return (
          <StyledButton
            data-test-id={dataTest}
            className={className}
            disabled={disabled || isLoading}
            onClick={onClick}
          >
            <div style={{ position: 'relative' }}>
              <StyledContentContainer style={{ opacity: isLoading ? 0.3 : 1 }}>{content}</StyledContentContainer>
              {
                <StyledSpinnerContainer style={{ opacity: isLoading ? 1 : 0 }}>
                  <Spinner size={24} />
                </StyledSpinnerContainer>
              }
            </div>
          </StyledButton>
        );
      }}
    />
  );
}

export type AsyncButtonState = {
  isLoading: boolean;
  isSucceeded: boolean;
  onClick: () => void;
};

export type AsyncButtonProps = {
  isSucceeded?: boolean;
  isLoading?: boolean;
  onClickAsync: () => Promise<void | 'error'>;
};

export function AsyncButtonBase({
  render,
  onClickAsync,
  isLoading,
  isSucceeded,
}: AsyncButtonProps & {
  render: (state: AsyncButtonState) => ReactNode;
}) {
  const [loading, setLoading] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const isMounted = useIsMounted();
  const snackbar = useSnackbar();

  const clickHandler = useCallback(async () => {
    setLoading(true);
    setIsOk(false);
    try {
      const r = await onClickAsync();
      setIsOk(r !== 'error');
    } catch (error: any) {
      if (error.toString) {
        const message: string = error.toString();
        snackbar.show({ message });
      } else snackbar.show({ message: `${error}` });
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [isMounted, onClickAsync, snackbar]);

  const state = useMemo(
    () => ({
      isLoading: loading || isLoading || false,
      isSucceeded: isOk || isSucceeded || false,
      onClick: clickHandler,
    }),
    [loading, isOk, clickHandler, isLoading, isSucceeded]
  );

  return <>{render(state)}</>;
}
