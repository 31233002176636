import { ReactNode, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { AdaptiveContext } from '../Utils/controls/adaptive';
import { Spinner } from '../Utils/controls/SmallSpinner';
import { ImperativeApiContext, useImperativeApiContainer } from '../Utils/hooks/makeImperativeApi';
import { AppRouting } from './AppRouting';
import { useEnvironmentLoader } from './Environment';
import { AppFirebaseWrapper } from './FirebaseWrapper';

import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

export function AppComponent() {
  return (
    <AppEnvironmentLoaderWrapper>
      <UiHelpersWrapper>
        <AppRouting />
      </UiHelpersWrapper>
    </AppEnvironmentLoaderWrapper>
  );
}

function AppEnvironmentLoaderWrapper({ children }: { children: ReactNode }) {
  const env = useEnvironmentLoader();

  if (!env) {
    return <Spinner />;
  }

  return <AppFirebaseWrapper env={env}>{children}</AppFirebaseWrapper>;
}

function UiHelpersWrapper({ children }: { children: ReactNode }) {
  const adaptiveContext = useContext(AdaptiveContext);
  const rootDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const s = adaptiveContext.onResetScroll.subscribable(() => {
      if (rootDivRef.current) {
        rootDivRef.current.scrollTo(0, 0);
      }
    });
    return () => s.unsubscribe();
  }, [adaptiveContext.onResetScroll]);

  const { width, height } = useResizeDetector();

  useLayoutEffect(() => {
    if (width !== undefined && height !== undefined) {
      adaptiveContext.width = width;
      adaptiveContext.height = height;
      adaptiveContext.onChanged.fire();
    }
  }, [width, height, adaptiveContext]);

  const { container, context } = useImperativeApiContainer();

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <NotificationsProvider>
        <ImperativeApiContext.Provider value={context}>{children}</ImperativeApiContext.Provider>
        {container}
      </NotificationsProvider>
    </MantineProvider>
  );
}
