import { onSnapshot, query } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { RequireLoginOrRedirect } from '../Shell/authentication/RequireLoginOrRedirect';
import { useUser } from '../Shell/authentication/UserInfoContext';
import { TypedTabs } from '../UI/TypedTabs';
import { assertNever } from '../Utils/typescript';
import { useUserInfoCollection } from '../firebase';
import { NestedMap, makeNestedMap, mapToArray } from '../models/commons';
import { UserInfoRecord } from '../models/user';
import { MyEventsComponent } from './Events';
import { AddEventComponent } from './NewEventComponent';

export function LandingComponent() {
  return <RequireLoginOrRedirect render={() => <LandingComponentLoggedIn />} />;
}

export function LandingComponentLoggedIn() {
  const [tab, setTab] = useState<'upcoming' | 'past'>('upcoming');
  const user = useUser();
  const usersCollection = useUserInfoCollection();
  const allEventRefs = useMemo(() => mapToArray(user.events), [user]);
  const events = useMemo(
    () => allEventRefs.filter((e) => e.event.time.toDate().getTime() >= new Date().getTime()),
    [allEventRefs]
  );

  const pastEvents = useMemo(
    () => allEventRefs.filter((e) => e.event.time.toDate().getTime() < new Date().getTime()),
    [allEventRefs]
  );

  const [membersSuggestions, setMembersSuggestions] = useState<NestedMap<UserInfoRecord>>({});

  // Currently get all members as suggestion, eventually get members from user's friends
  const membersQuery = useMemo(() => query(usersCollection), [usersCollection]);
  useEffect(() => {
    return onSnapshot(membersQuery, (snapshot) => {
      setMembersSuggestions(
        makeNestedMap((x: UserInfoRecord) => x.firebaseAuthUserId, ...snapshot.docs.map((x) => x.data()))
      );
    });
  }, [membersQuery]);

  return (
    <div>
      <main>
        <div className='mx-auto max-w-7xl py-6 sm:px-6 lg:px-8'>
          <AddEventComponent login={user} membersSuggestions={membersSuggestions} />
          <TypedTabs
            selectedValue={tab}
            allValues={['upcoming', 'past']}
            onSelectedValueChange={setTab}
            render={(tab) => {
              if (tab === 'upcoming') {
                return {
                  header: 'Upcoming Events',
                  tab: <MyEventsComponent events={events} />,
                };
              } else if (tab === 'past') {
                return {
                  header: 'Past Events',
                  tab: <MyEventsComponent events={pastEvents} />,
                };
              } else {
                return assertNever(tab);
              }
            }}
          />
        </div>
      </main>
    </div>
  );
}
