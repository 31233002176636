import { useLocation, useNavigate } from 'react-router';
import { Button } from '../../UI/Button';
import { BaseRoute } from '../RouteDefinitions';

export function GoToAppButtonComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const st: any = location.state;
  const from = st?.from?.pathname;

  return (
    <Button
      className='self-center'
      onClick={() => {
        navigate(from ? from : BaseRoute.makePath());
      }}
    >
      Go to app!
    </Button>
  );
}
