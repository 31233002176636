import { ReactNode } from 'react';
import { Params, useParams } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { assertNonNullish } from '../Utils/typescript';

const emptySearchParams = {} as const;

function trivialPathSearchParams<TSearchParams extends Record<string, string> = {}>(
  p: string,
  parseSearchParams: (r: SearchParams) => TSearchParams
) {
  return {
    makePath: (searchParams?: TSearchParams) => {
      return { pathname: `/${p}`, search: `${createSearchParams(searchParams)}` };
    },
    route: (render: (sp: TSearchParams) => ReactNode) => {
      return {
        path: `/${p}`,
        element: (
          <ParamWrapper<void, TSearchParams>
            render={(_, sp) => render(sp)}
            parseParams={() => { }}
            parseSearchParams={(sp) => {
              const sp2 = parseUrlSearchParams(sp);
              return parseSearchParams(sp2);
            }}
          />
        ),
      };
    },
  };
}

function trivialPath(p: string) {
  if (p.startsWith('/')) p = p.substring(1);

  return {
    makePath: () => {
      return `/${p}`;
    },
    route: (render: () => ReactNode) => {
      return {
        path: `/${p}`,
        element: (
          <ParamWrapper
            render={(_, sp) => render()}
            parseParams={() => { }}
            parseSearchParams={() => {
              return emptySearchParams;
            }}
          />
        ),
      };
    },
  };
}

type SearchParams = Map<string, string | string[]>;

function parseUrlSearchParams(searchParams: URLSearchParams): SearchParams {
  const sp: SearchParams = new Map<string, string | string[]>();
  for (const p of searchParams.entries()) {
    const [key, value] = p;
    if (sp.has(key)) {
      const current = assertNonNullish(sp.get(key));
      if (typeof current === 'string') {
        sp.set(key, [current, value]);
      } else {
        sp.set(key, [...current, value]);
      }
    } else {
      sp.set(key, value);
    }
  }
  return sp;
}

function ParamWrapper<TParams, TSearchParams extends Record<string, string> = {}>({
  render,
  parseParams,
  parseSearchParams,
}: {
  render: (p: TParams, sp: TSearchParams) => ReactNode;
  parseParams: (p: Params) => TParams;
  parseSearchParams: (p: URLSearchParams) => TSearchParams;
}) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const parsedSearchParams = parseSearchParams(searchParams);
  const parsed = parseParams(params);
  return <>{render(parsed, parsedSearchParams)}</>;
}

function paramPath<TParams>({
  parseParams,
  makePath,
  matchPath,
}: {
  matchPath: string;
  makePath: (params: TParams) => string;
  parseParams: (r: Params) => TParams;
}) {
  return {
    makePath: (p: TParams) => {
      return makePath(p);
    },
    route: (render: (p: TParams) => ReactNode) => {
      return {
        path: matchPath,
        element: (
          <ParamWrapper
            render={render}
            parseParams={parseParams}
            parseSearchParams={(sp) => {
              return {};
            }}
          />
        ),
      };
    },
  };
}

// export const QrCodeRoute = paramPath<{
//   locationToken: string;
//   days?: number | 'forever';
// }>({
//   matchPaths: ['/start/:locationToken/:days?'],
//   makePath: ({locationToken, days}) => {
//     if (days === undefined) {
//       return `/start/${locationToken}`;
//     }
//     return `/start/${locationToken}/${days}`;
//   },
//   parseParams: (r) => {
//     return {
//       locationToken: r.params.locationToken!,
//       days: r.params.days ? (r.params.days === 'forever' ? 'forever' : parseInt(r.params.days)) : undefined,
//     };
//   },
// });

export type EditChatRouteParams = { type: 'edit-chat'; chatId: string };
export type ChatMessageDestinationDto2 = { type: 'chat'; chatId: string };
export type DirectMessageDestinationDto2 = { type: 'direct'; userId: string };
export type MessageDestinationDto2 =
  | { type: 'default-location' }
  | ChatMessageDestinationDto2
  | DirectMessageDestinationDto2
  | EditChatRouteParams;
export type MainRouteParams = 'home' | 'chats' | 'edit-info' | { destination: MessageDestinationDto2 };

export type ResolvedDestination = ChatMessageDestinationDto2 | DirectMessageDestinationDto2 | EditChatRouteParams;

export function getCurrentResolvedDestination(
  params: MainRouteParams,
  lastChatId: string | undefined
): ResolvedDestination | null {
  if (
    params === 'home' ||
    params === 'chats' ||
    params === 'edit-info' ||
    params.destination.type === 'default-location'
  ) {
    if (lastChatId) return { type: 'chat' as const, chatId: lastChatId };
    return null;
  }
  return params.destination;
}

export type LoginPageTab = 'sign-in' | 'register';

export type LoginPageParams =
  | { tab?: LoginPageTab; mode: 'login' }
  | { mode: 'requestPasswordReset'; email?: string }
  | { mode: 'verifyEmail'; oobCode: string }
  | { mode: 'resetPassword'; oobCode: string };

export const LoginRoute = trivialPathSearchParams<LoginPageParams>('login', (r) => {
  const mode = r.get('mode');
  if (mode !== undefined && typeof mode !== 'string') {
    throw new Error();
  }
  const oobCode = r.get('oobCode') ?? '';
  if (oobCode !== undefined && typeof oobCode !== 'string') {
    throw new Error();
  }

  const email = r.get('email');
  if (email !== undefined && typeof email !== 'string') {
    throw new Error();
  }

  if (mode === 'requestPasswordReset') {
    return { mode: 'requestPasswordReset', email };
  }

  if (mode === 'verifyEmail') {
    return { mode: 'verifyEmail', oobCode };
  }

  if (mode === 'resetPassword') {
    return { mode: 'resetPassword', oobCode };
  }

  const tab = r.get('tab');
  if (tab !== undefined && tab !== 'sign-in' && tab !== 'register') {
    throw new Error();
  }
  return { mode: 'login', tab };
});
export const BaseRoute = trivialPath('/');

export const MyEventRoute = paramPath<{ id: string }>({
  matchPath: 'event/:id',
  makePath: (params) => `/event/${params.id}`,
  parseParams: (r) => {
    const id = r.id;
    if (!id) {
      throw new Error();
    }
    return { id };
  },
});

export const HelpRoute = trivialPath('help');
export const VenueInfoRoute = trivialPath('venue-info');
export const UserInfoRoute = trivialPath('landing');

export const DemoRoute = trivialPath('demo');
export const PreprintedQrCodeRoute = trivialPath('print-qr');
export const ContactRoute = trivialPath('contact');
export const BetaRoute = trivialPath('beta');
export const DeleteAccountRoute = trivialPath('delete-account');
// export const QrPrintRoute = paramPath<{roomId: string}>({
//   makePath: (params) => {
//     return '/qr-print/' + params.roomId;
//   },
//   matchPaths: ['/qr-print/:roomId'],
//   parseParams: (r) => {
//     return {roomId: r.params.roomId!};
//   },
// });
