import eventIcon from '@iconify-icons/heroicons/cake-20-solid';
import { Icon } from '@iconify/react';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { MyEventRoute } from '../Shell/RouteDefinitions';
import { WithId } from '../models/commons';
import { EventSnippet } from '../models/event';

export default function EventSnippetComponent({ eventSnippet }: { eventSnippet: WithId<EventSnippet> }): ReactElement {
  const navigate = useNavigate();

  return (
    <div
      className='p-2 w-full flex justify-center '
      onClick={() => navigate(MyEventRoute.makePath({ id: eventSnippet.documentId }))}
    >
      <div className='w-full cursor-pointer p-4 flex flex-col md:flex-row rounded-lg bg-white shadow-lg'>
        <Icon icon={eventIcon} width={200} />

        <div className='p-4 flex flex-col justify-start'>
          <h5 className='text-gray-900 text-xl font-medium mb-2'>{eventSnippet.name}</h5>
          <p className='text-gray-700 text-base mb-4'>{eventSnippet.description}</p>
        </div>
      </div>
    </div>
  );
}
