import checkIcon from '@iconify-icons/ion/checkmark';
import { Icon } from '@iconify/react';
import { applyActionCode, sendEmailVerification } from 'firebase/auth';
import { ReactElement, useState } from 'react';
import { useFirebaseAuth, useFirebaseAuthState } from '../../firebase';
import { AsyncButton } from '../../Utils/controls/AsyncButton';
import { Spinner } from '../../Utils/controls/SmallSpinner';
import { useAsyncEffect } from '../../Utils/hooks/useAsyncEffect';
import { assertNever } from '../../Utils/typescript';
import { AuthenticationForm } from './AuthenticationForm';
import { GoToAppButtonComponent } from './GoToAppButtonComponent';

export function VerifyEmailComponent({ oobCode }: { oobCode: string }): ReactElement {
  const auth = useFirebaseAuth();
  const authState = useFirebaseAuthState();

  const [isLoading, setIsLoading] = useState<'applying-code' | 'code-applied' | 'wrong-code' | 'already-verified'>(
    'applying-code'
  );

  useAsyncEffect(async () => {
    if (oobCode) {
      if (auth.currentUser?.emailVerified) {
        setIsLoading('already-verified');
        return;
      }
      setIsLoading('applying-code');
      try {
        await applyActionCode(auth, oobCode);
        setIsLoading('code-applied');
      } catch (e) {
        console.log(e);
        setIsLoading('wrong-code');
      }
    }
  }, [oobCode]);

  if (authState.type === 'loading') {
    return <Spinner />;
  }

  if (authState.type === 'logged-out') {
    return <AuthenticationForm />;
  }

  if (isLoading === 'applying-code' || isLoading === 'code-applied' || isLoading === 'already-verified') {
    const codeApplied = isLoading === 'code-applied' || isLoading === 'already-verified';
    return (
      <div className='flex-col items-stretch'>
        <div className='flex items-center mb-3'>
          <div className='mr-2'>Verifying email...</div>
          {codeApplied ? <Icon icon={checkIcon} /> : <Spinner />}
        </div>
        {codeApplied && (
          <div className='flex items-center'>
            <div className='mr-2'>Your email has been verified.</div>
            <GoToAppButtonComponent />
          </div>
        )}
      </div>
    );
  }

  if (isLoading === 'wrong-code') {
    return (
      <div>
        <div>The code is outdated.</div>
        <AsyncButton
          onClickAsync={async () => {
            await sendEmailVerification(authState.user);
          }}
        >
          Resend confirmation email
        </AsyncButton>
      </div>
    );
  }

  return assertNever(isLoading);
}
