import { Icon } from '@iconify/react';
import { CenterMaxWidth, contentMaxWidth } from '../Utils/controls/LayoutStyles';

import { Menu, Transition } from '@headlessui/react';
import icon from '@iconify-icons/lucide/venetian-mask';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';
import { useFirebaseAuth } from '../firebase';
import { useFirebaseUserInfo } from '../Shell/authentication/firebase-user-info-context';
import { BaseRoute } from '../Shell/RouteDefinitions';
import { LinkLikeButton } from '../UI/NavigationLinks';
import { CircledUserImageComponent } from './UserImage';

export function HeaderComponent() {
  const navigate = useNavigate();
  const auth = useFirebaseAuth();
  const user = useFirebaseUserInfo();

  return (
    <CenterMaxWidth maxWidth={contentMaxWidth} className='bg-slate-500 self-stretch'>
      <div className='flex items-center justify-between text-white' data-test-id='header'>
        <div className='h-16 pl-4 pr-2 flex items-stretch'>
          <div className='py-2 cursor-pointer' onClick={() => navigate(BaseRoute.makePath())}>
            <Icon icon={icon} height='100%' />
          </div>
          <div className='flex justify-center items-center px-2'>
            {auth.state.type === 'logged-in' && <>{/* Put Tabs here */}</>}
          </div>
        </div>

        <div className='flex gap-4 items-center'>
          <div className='flex flex-col items-end' data-test-id='user-welcome'>
            {user.state === 'logged-in' && (
              <>
                <div>Welcome to Cabaret,</div>
                <div data-test-id='user-name'>{user.userInfo.displayName}</div>
              </>
            )}
          </div>
          {/* Profile dropdown */}
          <Menu as='div' className='h-16'>
            <Menu.Button as='div' className='h-full flex relative pr-4 py-1 cursor-pointer'>
              <span className='sr-only'>Open user menu</span>
              <UserProfile />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <Menu.Item>
                  <LinkLikeButton
                    className='block px-4 py-2 text-sm text-gray-700'
                    onClick={() => auth.signOut()}
                    style={{ marginLeft: '10px' }}
                  >
                    Logout
                  </LinkLikeButton>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </CenterMaxWidth>
  );
}

function UserProfile() {
  const login = useFirebaseUserInfo();
  if (login.state !== 'logged-in') {
    return null;
  }
  const user = login.userInfo;

  return <CircledUserImageComponent size='56px' user={user} />;
}
