import { AuthenticationForm } from '../Shell/authentication/AuthenticationForm';
import { ResetPasswordComponent } from '../Shell/authentication/ResetPasswordComponent';
import { VerifyEmailComponent } from '../Shell/authentication/VerifyEmaiComponent';
import { LoginPageParams } from '../Shell/RouteDefinitions';
import { RequestPasswordResetComponent } from './RequestPasswordResetComponent';

export function LoginComponent({ params }: { params: LoginPageParams }) {
  if (params.mode === 'verifyEmail') {
    return <VerifyEmailComponent oobCode={params.oobCode} />;
  }
  if (params.mode === 'resetPassword') {
    return <ResetPasswordComponent oobCode={params.oobCode} />;
  }
  if (params.mode === 'requestPasswordReset') {
    return <RequestPasswordResetComponent email={params.email} />;
  }

  return <AuthenticationForm tab={params.tab} />;
}
