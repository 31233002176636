import { Navigate, useLocation } from 'react-router';
import { Spinner } from '../../Utils/controls/SmallSpinner';
import { useFirebaseUserInfo } from './firebase-user-info-context';

export function RequireLoginOrRedirect({ render }: { render: () => JSX.Element }) {
  let location = useLocation();
  const login = useFirebaseUserInfo();
  if (login.state === 'getting-user-info' || login.state === 'loading') {
    return <Spinner />;
  }
  if (login.state !== 'logged-in') {
    console.log('Redirecting to log in page');
    return <Navigate to='/login' state={{ from: location }} replace />;
  }
  return render();
}
