import { ReactNode } from 'react';
import { getHashCode } from '../Utils/typescript';
import { SegmentedControl } from '@mantine/core';

export function TypedTabs<TKeys extends string>({
  selectedValue,
  onSelectedValueChange,
  allValues,
  render,
}: {
  selectedValue: TKeys;
  allValues: TKeys[];
  onSelectedValueChange: (newValue: TKeys) => void;
  render: (tab: TKeys) => { tab: ReactNode; header: ReactNode };
}) {
  const renderedTabs = allValues.map((x) => ({ ...render(x), value: x }));
  const selectedIndex = allValues.findIndex((x) => x === selectedValue);

  return (
    <>
      <div className='flex flex-col items-center'>
        <div className='flex self-stretch' data-test-id='tab-headers-panel'>
          <SegmentedControl
            size='md'
            className='grow'
            value={getHashCode(selectedValue).toString()}
            onChange={(hashCode) =>
              onSelectedValueChange(allValues.find((x) => getHashCode(x).toString() === hashCode)!)
            }
            data={renderedTabs.map((x, i) => ({
              disabled: false,
              value: getHashCode(x.value).toString(),
              label: (
                <div key={getHashCode(x.value)} data-test-id={x.value}>
                  {x.header}
                </div>
              ),
            }))}
          />
        </div>
        {renderedTabs[selectedIndex].tab}
      </div>
    </>
  );
}
