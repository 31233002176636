import { useContext } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { AdaptiveContext } from '../Utils/controls/adaptive';

export const LinkLikeButton = styled.div`
  display: inline-flex;
  align-items: center;

  cursor: pointer;
  text-decoration: underline;
  color: #7d7dff;

  :hover {
    color: #9292fd;
  }
`;

export function StyledRouterLink({ onClick, style, ...props }: LinkProps) {
  const adaptiveContext = useContext(AdaptiveContext);
  return (
    <Link
      style={{ color: 'unset', ...style }}
      onClick={(e) => {
        adaptiveContext.onResetScroll.fire();
        if (onClick) {
          onClick(e);
        }
        e.stopPropagation();
      }}
      {...props}
    />
  );
}
