import 'firebase/compat/firestore';
import { UserObjectReference } from './user';

export type NestedMap<T> = { [id: string]: T };

export function mapToArray<T>(map: NestedMap<T> | null | undefined): Array<T & { id: string }> {
  if (!map) return [];
  const r = [];
  for (const usersKey in map) {
    if (map.hasOwnProperty(usersKey)) {
      r.push({ id: usersKey, ...map[usersKey] });
    }
  }
  return r;
}

export function mapToArrayWithIdKey<T, TKey extends string>(
  idKey: TKey,
  map: NestedMap<T> | null | undefined
): ({
  [p in TKey]: string;
} & T)[] {
  if (!map) return [];
  const r: Array<
    {
      [p in TKey]: string;
    } & T
  > = [];
  for (const usersKey in map) {
    if (map.hasOwnProperty(usersKey)) {
      r.push({ [idKey]: usersKey, ...map[usersKey] } as any);
    }
  }
  return r;
}

export function makeNestedMap<T>(idSelector: (i: T) => string, ...items: T[]) {
  const addUsers: NestedMap<T> = {};
  for (const item of items) {
    addUsers[idSelector(item)] = item;
  }
  return addUsers;
}

// todo: why is this any?
export function makeInsertValue<T>(id: string, item: T): any {
  const addUsers: NestedMap<T> = {};
  addUsers[id] = item;
  return addUsers;
}

export function formatUnreadMessages(count: number, maximum: number) {
  if (count <= maximum) return count;
  return `${maximum}+`;
}

export type PlaceRecord = {
  name: string;
  managers: NestedMap<UserObjectReference>;
};

export type CommentInfo = {
  text: string;
  dateCreated: string;
  author: UserObjectReference;
};

export type WithId<T> = T & { documentId: string };
