import check from '@iconify-icons/ion/checkmark';
import { Icon } from '@iconify/react';
import React, { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { AsyncButtonBase } from '../Utils/controls/AsyncButton';
import { FlexCenterColumn, FlexCenterRow, FlexColumn, FlexFiller } from '../Utils/controls/LayoutStyles';
import { SmallSpinner } from '../Utils/controls/SmallSpinner';
import { StyledButton, StyledCard } from './styles';

export const HeaderHeight = '80px';
export const ChatMaxWidth = '960px';

export const StyledMainButton = styled.button`
  cursor: pointer;
  border: 0;
  background: #fc385c;
  box-shadow: 0 5px 15px #fc385c40;
  border-radius: 10px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 10px;

  :hover {
    background: #ff4a6c;
  }

  :disabled {
    cursor: auto;
    background: rgba(69, 77, 90, 0.3);
    box-shadow: 0 5px 15px rgba(224, 224, 224, 0.25);
  }

  transition: background-color 0.2s ease;
`;

export function StyledMainAsyncButton({
  children,
  onClickAsync,
  ...buttonProps
}: Omit<
  Partial<React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>,
  'onClick' | 'ref'
> & { onClickAsync: () => Promise<void> }) {
  return (
    <AsyncButtonBase
      render={({ isLoading, isSucceeded, onClick }) => (
        <StyledMainButton
          disabled={isLoading}
          onClick={onClick}
          style={{
            marginTop: '30px',
            minWidth: '300px',
            minHeight: '60px',
            fontSize: '16px',
            fontWeight: 700,
            letterSpacing: '.125em',
          }}
          {...buttonProps}
        >
          <FlexFiller />
          <div>{children ?? 'Save'}</div>
          <FlexFiller
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isLoading ? <SmallSpinner /> : isSucceeded && <Icon icon={check} width={24} height={24} />}
          </FlexFiller>
        </StyledMainButton>
      )}
      onClickAsync={onClickAsync}
    />
  );
}

export const StyledBlackButton = styled.div`
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4d5765;
  height: 40px;
  color: white;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledIconButton = styled(StyledButton)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function MenuIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
      <path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z' />
    </svg>
  );
}

export const PrimaryRed = '#fc385c';
export const PrimaryBlack = '#444d59';
export const BackgroundGray = '#f3f2f3';
export const LightGray = '#f8f8f9';

export const StyledMenuLinkButton = styled(FlexCenterRow)`
  cursor: pointer;
  align-self: stretch;

  :hover {
    text-decoration: underline;
  }

  > div {
    margin-left: 10px;
  }
`;

export const StyledPopupMenu = styled(FlexCenterColumn)`
  padding: 10px 30px;
  max-width: 300px;

  ${StyledMenuLinkButton}:not(:first-child) {
    margin-top: 15px;
  }
`;

export const StyledHoverMenuButton = styled(FlexCenterRow)<{
  active: boolean;
  whiteColor?: boolean;
}>`
  font-weight: 500;
  letter-spacing: 0;
  font-size: 14px;
  cursor: pointer;

  min-width: 40px;
  min-height: 40px;

  justify-content: center;

  color: ${(props) => (props.active ? PrimaryRed : props.whiteColor ? 'white' : PrimaryBlack)};

  :hover {
    color: ${PrimaryRed};
  }

  > div {
    margin-left: 10px;
  }
`;

export function StyledMenuButton({
  onClick,
  active,
  whiteColor,
  style,
  testName,
}: {
  onClick: () => void;
  active?: boolean;
  whiteColor?: boolean;
  style?: CSSProperties;
  testName?: string;
}) {
  return (
    <StyledHoverMenuButton
      onClick={onClick}
      active={active ?? false}
      data-test-id={testName}
      whiteColor={whiteColor ?? false}
      style={style}
    >
      <MenuIcon />
    </StyledHoverMenuButton>
  );
}

export function MobileInfoCard({
  title,
  children,
  style,
}: {
  title?: ReactNode;
  children?: ReactNode;
  style?: CSSProperties;
}) {
  return (
    <StyledCard
      style={{
        padding: '20px 30px',
        fontSize: '12px',
        fontWeight: 500,
        letterSpacing: '0.05em',
        boxShadow: '0px 5px 10px #0000001A',
        borderRadius: '8px 8px 8px 0px',
        ...style,
      }}
    >
      <div
        style={{
          fontSize: '16px',
          marginBottom: '12px',
          fontWeight: 600,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title ?? 'About'}
      </div>
      {children}
    </StyledCard>
  );
}

export const HintText = styled.div`
  font-size: smaller;
  color: rgba(68, 77, 89, 0.6);
`;

export const InputHeight = '50px';

export const StyledInputGroup = styled(FlexColumn)`
  margin-top: 20px;
  align-items: stretch;

  > div:first-child {
    font-size: 12px;
    margin-left: 16px;
    margin-bottom: 4px;
  }

  > input,
  > textarea {
    background: ${LightGray};
    border-radius: 8px;
    font-size: 14px;
    height: ${InputHeight};
    padding: 16px;

    ::placeholder {
      opacity: 30%;
    }
  }

  > textarea {
    height: 80px;
  }
`;
