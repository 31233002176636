import React from 'react';
import styled from 'styled-components';
import {StyledButton} from './styles';
import {FlexColumn, FlexRow} from '../Utils/controls/LayoutStyles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;
}

const MainStyledButton = styled(StyledButton)`
  & > div {
    justify-content: center;
    flex: 1 1 0;
    align-items: center;
  }
`;

export function Button({icon, children, ...props}: ButtonProps) {
  return (
    <MainStyledButton {...props}>
      <FlexRow>
        {icon && <FlexColumn>{icon}</FlexColumn>}
        <FlexColumn>{children}</FlexColumn>
      </FlexRow>
    </MainStyledButton>
  );
}
