import { User } from 'firebase/auth';
import { createContext, useContext } from 'react';
import { UserInfoRecord } from '../../models/user';

export type LoginStateType =
  | { state: 'loading' }
  | { state: 'logged-out' }
  | { state: 'logged-in-unverified'; firebaseUser: User }
  | { state: 'getting-user-info'; firebaseUser: User }
  | {
      state: 'logged-in';
      firebaseUser: User;
      userInfo: UserInfoRecord & { documentId: string };
    };

export const FirebaseUserInfoContext = createContext<{
  loginState: LoginStateType;
  setUserInfo: (userInfo: UserInfoRecord & { documentId: string }) => void;
}>({
  loginState: { state: 'loading' },
  setUserInfo: () => {},
});

export type UserInfoRecordWithId = UserInfoRecord & { documentId: string };

export function useFirebaseUserInfo(): LoginStateType {
  const context = useContext(FirebaseUserInfoContext);
  return context.loginState;
}
