import * as firebase from 'firebase/app';
import { ReactNode, useMemo } from 'react';
import { FirebaseContext } from '../firebase';

import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { Spinner } from '../Utils/controls/SmallSpinner';
import { getNicerAuth } from './authentication/firebase-auth-helper';
import { UserInfoContext } from './authentication/UserInfoContext';
import { Environment, environmentContext } from './Environment';

export function AppFirebaseWrapper({ env, children }: { env: Environment; children: ReactNode }) {
  const firebaseApp = useMemo(() => {
    let app;

    if (!firebase.getApps().length) {
      app = firebase.initializeApp({
        apiKey: env.apiKey,
        authDomain: env.authDomain,
        projectId: env.projectId,
        storageBucket: env.storageBucket,
        messagingSenderId: env.messagingSenderId,
        appId: env.appId,
        measurementId: env.measurementId,
      });
      if (env.backendType !== 'prd') {
        const firestore = getFirestore();
        connectFirestoreEmulator(firestore, 'localhost', 8080);

        const auth = getAuth();
        connectAuthEmulator(auth, 'http://localhost:9099');

        const storage = getStorage();
        connectStorageEmulator(storage, 'localhost', 9199);

        const analytics = getAnalytics();
        setAnalyticsCollectionEnabled(analytics, false);
      }
    } else {
      app = firebase.getApp();
    }

    const firestore = getFirestore(app);
    const auth = getNicerAuth(app);
    const storage = getStorage(app);
    const analytics = getAnalytics(app);

    return { firestore, auth, storage, analytics };
  }, [env]);

  if (firebaseApp === null) {
    return <Spinner />;
  }

  return (
    <environmentContext.Provider value={env}>
      <FirebaseContext.Provider value={firebaseApp}>
        <UserInfoContext>{children}</UserInfoContext>
      </FirebaseContext.Provider>
    </environmentContext.Provider>
  );
}
