import { NotificationProps, showNotification } from '@mantine/notifications';

export function useSnackbar(): {
  show: (content: NotificationProps) => void;
} {
  return {
    show: (content) => {
      showNotification(content);
    },
  };
}
