import checkIcon from '@iconify-icons/ion/checkmark';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useFirebaseAuth } from '../../firebase';
import { TextFormField } from '../../UI/FormField';
import { LinkLikeButton } from '../../UI/NavigationLinks';
import { AsyncButton } from '../../Utils/controls/AsyncButton';
import { SmallSpinner } from '../../Utils/controls/SmallSpinner';
import { useAsyncCallback } from '../../Utils/hooks/useAsyncCallback';
import { assertNever } from '../../Utils/typescript';
import { LoginRoute } from '../RouteDefinitions';
import { confirmPasswordReset, verifyPasswordResetCode } from './firebase-helpers';
import { GoToAppButtonComponent } from './GoToAppButtonComponent';

export function ResetPasswordComponent({ oobCode }: { oobCode: string }): JSX.Element {
  const [oobValidated, setOobValidated] = useState(false);

  return (
    <>
      <ValidatingCodeRow oobCode={oobCode} onValidated={() => setOobValidated(true)} />
      {oobValidated && <ResetInputs oobCode={oobCode} />}
    </>
  );
}

function ResetInputs({ oobCode }: { oobCode: string }) {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string>();
  const auth = useFirebaseAuth();

  const passwordTooShort = password.length < 6;
  const passwordMismatch = password !== passwordConfirmation;

  const isInvalid = passwordTooShort || passwordMismatch;

  return (
    <>
      <TextFormField
        label='Password'
        value={password}
        disabled={isLoading || isSuccess}
        onChange={setPassword}
        type='password'
        errors={[[passwordTooShort, 'Password is too short']]}
      />
      <TextFormField
        label='Password confirmation'
        disabled={isLoading || isSuccess}
        value={passwordConfirmation}
        onChange={setPasswordConfirmation}
        type='password'
        errors={[[passwordMismatch, 'Passwords do not match']]}
      />
      <AsyncButton
        disabled={isInvalid || isSuccess}
        onClickAsync={async () => {
          setIsLoading(true);
          setError(undefined);

          const r = await confirmPasswordReset(auth, oobCode, password);
          setIsLoading(false);
          if (r.ok === true) {
            setIsSuccess(true);
          } else {
            setError('Could not reset password. Try requesting new password reset link.');
          }
        }}
      >
        Reset
      </AsyncButton>
      {error && <div className='text-red-500'>{error}</div>}
      {isSuccess && (
        <div className='flex flex-col'>
          <div>Password changed successfully</div>
          <GoToAppButtonComponent />
        </div>
      )}
    </>
  );
}

function ValidatingCodeRow({ oobCode, onValidated }: { oobCode: string; onValidated: () => void }) {
  const auth = useFirebaseAuth();
  const [state, setState] = useState<{ state: 'validating' } | { state: 'error' } | { state: 'validated' }>({
    state: 'validating',
  });

  const navigate = useNavigate();

  const validateCode = useAsyncCallback(
    async ({ wrap }) => {
      setState({ state: 'validating' });
      const r = await wrap(verifyPasswordResetCode(auth, oobCode));
      if (r.ok === true) {
        setState({ state: 'validated' });
        onValidated();
      } else if (r.ok === 'error-status-code') {
        setState({ state: 'error' });
      } else if (r.ok === undefined) {
        setState({ state: 'error' });
      } else {
        assertNever(r);
      }
    },
    [auth, oobCode]
  );

  useEffect(() => {
    validateCode();
  }, [validateCode]);

  return (
    <div className='flex flex-col items-center p-4'>
      <div className='pr-3'>Validating code...</div>
      {state.state === 'validating' && <SmallSpinner />}
      {state.state === 'validated' && <Icon icon={checkIcon} />}
      {state.state === 'error' && (
        <div className='flex flex-col items-center'>
          There was an error validating the code.{' '}
          <LinkLikeButton onClick={() => validateCode()}>Try again</LinkLikeButton> or{' '}
          <LinkLikeButton onClick={() => navigate(LoginRoute.makePath({ mode: 'requestPasswordReset' }))}>
            request another password reset link
          </LinkLikeButton>
        </div>
      )}
    </div>
  );
}
