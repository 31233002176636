import { EventRefForUser } from '../models/user';
import { FlexCenterColumn } from '../Utils/controls/LayoutStyles';
import EventSnippetComponent from './EventSnippetComponent';

export function MyEventsComponent({ events }: { events: Array<EventRefForUser> }) {
  return (
    <FlexCenterColumn className='w-full'>
      {events.length === 0 && <div>No Events yet.</div>}
      {events.map((p) => (
        <EventSnippetComponent key={p.event.documentId} eventSnippet={p.event} />
      ))}
    </FlexCenterColumn>
  );
}
