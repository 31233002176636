import { doc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { ReactNode, useCallback, useContext, useMemo, useRef } from 'react';
import { useIsMounted } from '../../Utils/controls/useIsMounted';
import { useEffectDebug } from '../../Utils/hooks/useEffectDebug';
import { useForceUpdate } from '../../Utils/hooks/useForceUpdate';
import { nameof } from '../../Utils/typescript';
import { useFirebaseAuth, useUserInfoCollection } from '../../firebase';
import { UserInfoRecord, makeUserReference } from '../../models/user';
import { FirebaseUserInfoContext, LoginStateType } from './firebase-user-info-context';

export function UserInfoContext({ children }: { children: ReactNode }) {
  const userInfoCollection = useUserInfoCollection();
  const stateRef = useRef<LoginStateType>({ state: 'loading' });
  const isMounted = useIsMounted();
  const auth = useFirebaseAuth();
  const forceUpdate = useForceUpdate();

  const setState = useCallback(
    (newState: LoginStateType) => {
      stateRef.current = newState;
      forceUpdate();
    },
    [forceUpdate]
  );

  const setUserInfo = useCallback(
    (newInfo: UserInfoRecord & { documentId: string }) => {
      if (stateRef.current.state !== 'logged-in') return;
      setState({
        state: 'logged-in',
        firebaseUser: stateRef.current.firebaseUser,
        userInfo: newInfo,
      });
    },
    [setState]
  );

  const contextValue = useMemo(
    () => ({
      loginState: stateRef.current,
      setUserInfo,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUserInfo, stateRef.current]
  );

  const makeUserQuery = useMemo(
    () => (userId: string) => {
      return query(userInfoCollection, where(nameof<UserInfoRecord>('firebaseAuthUserId'), '==', userId));
    },
    [userInfoCollection]
  );

  const currentUser = auth.currentUser;
  const userQuery = useMemo(() => (currentUser ? makeUserQuery(currentUser.uid) : null), [currentUser, makeUserQuery]);

  useEffectDebug(() => {
    if (userQuery !== null) {
      return onSnapshot(userQuery, (s: any) => {
        if (stateRef.current.state !== 'logged-in') return;
        const doc = s.docs[0];
        if (!doc) {
          setState({ state: 'logged-out' });
          return;
        }
        const newUserInfo = doc.data();
        setState({
          ...stateRef.current,
          userInfo: { ...newUserInfo, documentId: doc.id },
        });
      });
    }
  }, [setState, userQuery]);

  useEffectDebug(() => {
    return auth.onNicerStateChange(async (state) => {
      if (state.type === 'logged-in') {
        if (!state.user.emailVerified) {
          setState({ state: 'logged-in-unverified', firebaseUser: state.user });
          return;
        }

        const user = state.user;
        setState({ state: 'getting-user-info', firebaseUser: user });
        try {
          const userInfoList = await getDocs(makeUserQuery(user.uid));
          if (!isMounted.current) return;
          const userInfo = userInfoList.docs[0];
          if (userInfo) {
            const data = userInfo.data();
            setState({
              state: 'logged-in',
              firebaseUser: user,
              userInfo: { ...data, documentId: userInfo.id },
            });
          } else {
            const newInfo: UserInfoRecord = {
              firebaseAuthUserId: user.uid,
              ...makeUserReference(user),
              events: {},
            };
            const userDocRef = doc(userInfoCollection, user.uid);
            await setDoc(userDocRef, newInfo);

            if (!isMounted.current) return;
            setState({
              state: 'logged-in',
              firebaseUser: user,
              userInfo: { ...newInfo, documentId: userDocRef.id },
            });
          }
        } catch (e: any) {
          console.log('could not get user info');
          console.log(e);
        }
      } else if (state.type === 'logged-out') {
        setState({ state: 'logged-out' });
      }
    });
  }, [auth, isMounted, setState, userInfoCollection]);

  return <FirebaseUserInfoContext.Provider value={contextValue}>{children}</FirebaseUserInfoContext.Provider>;
}

export function useUser() {
  const user = useOptionalUser();
  if (!user) {
    throw new Error('User not logged in');
  }
  return user;
}

export function useOptionalUser() {
  const context = useContext(FirebaseUserInfoContext);
  if (context.loginState.state !== 'logged-in') {
    return null;
  }
  return context.loginState.userInfo;
}