import { Route } from 'react-router';
import { BrowserRouter, Routes } from 'react-router-dom';
import { MyEventLoginComponent } from '../app/Event';
import { LandingComponent } from '../app/Landing';
import { LoginComponent } from '../app/Login';
import Root from '../app/Root';
import { BaseRoute, LoginRoute, MyEventRoute } from './RouteDefinitions';

export function AppRouting() {
  return (
    <BrowserRouter basename='/app'>
      <Routes>
        <Route element={<Root />}>
          <Route {...LoginRoute.route((params) => <LoginComponent params={params} />)} />
          <Route {...BaseRoute.route(() => <LandingComponent />)} />
          <Route {...MyEventRoute.route(({ id }) => <MyEventLoginComponent id={id} />)} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
