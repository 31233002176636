import { Button, Checkbox, FileInput, Group, Input, NumberInput, TextInput } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { Timestamp } from 'firebase/firestore';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { MyEventRoute } from '../Shell/RouteDefinitions';
import { UserInfoRecordWithId } from '../Shell/authentication/firebase-user-info-context';
import { combineDateWithTime, splitDateAndTime } from '../Shell/authentication/utils';
import { TypedSelect } from '../UI/TypedSelect';
import { StyledButton } from '../UI/tailwind-styles';
import { useDialogApi } from '../Utils/controls/useDialogApi';
import { addEventDoc, editEventDoc, uploadEventImage, useFirestore } from '../firebase';
import { NestedMap, WithId, mapToArrayWithIdKey } from '../models/commons';
import { EventRecord } from '../models/event';
import { UserInfoRecord, UserObjectReference, makeUserReferenceFromUserInfoRecord } from '../models/user';
import { CircledUserImageComponent } from './UserImage';

export function AddEventComponent({
  login,
  membersSuggestions,
}: {
  login: UserInfoRecordWithId;
  membersSuggestions: NestedMap<UserInfoRecord>;
}) {
  const dialog = useDialogApi();
  const navigate = useNavigate();
  return (
    <div className='p-2 inline-block'>
      <StyledButton
        data-test-id='add-event-button'
        onClick={async () => {
          const eventId = await dialog.show<string | undefined>({
            render: ({ resolve }) => (
              <NewEventComponent login={login} onAdd={resolve} membersSuggestions={membersSuggestions} />
            ),
            cancelResult: undefined,
            title: () => 'Create Event',
          });
          if (eventId !== undefined) {
            navigate(MyEventRoute.makePath({ id: eventId }));
          }
        }}
      >
        Add an Event
      </StyledButton>
    </div>
  );
}

export function EditEventComponent({
  login,
  membersSuggestions,
  event,
}: {
  login: UserInfoRecordWithId;
  membersSuggestions: NestedMap<UserInfoRecord>;
  event: WithId<EventRecord>;
}) {
  const dialog = useDialogApi();
  return (
    <div className='p-2 inline-block'>
      <StyledButton
        data-test-id='edit-event-button'
        onClick={async () => {
          await dialog.show<string | undefined>({
            render: ({ resolve }) => (
              <NewEventComponent login={login} onAdd={resolve} membersSuggestions={membersSuggestions} event={event} />
            ),
            cancelResult: undefined,
            title: () => 'Edit Event',
          });
        }}
      >
        Edit Event
      </StyledButton>
    </div>
  );
}

export function NewEventComponent({
  login,
  onAdd,
  membersSuggestions,
  event,
}: {
  login: UserInfoRecordWithId;
  onAdd: (eventId: string) => void;
  membersSuggestions: NestedMap<UserInfoRecord>;
  event?: WithId<EventRecord> | undefined;
}) {
  const firestore = useFirestore();
  const suggestionsArray: Array<WithId<UserObjectReference>> = useMemo(
    () =>
      mapToArrayWithIdKey('documentId' as const, membersSuggestions).map((m) => {
        return { documentId: m.documentId, ...makeUserReferenceFromUserInfoRecord(m) };
      }),
    [membersSuggestions]
  );
  const isEdit = event != null;
  const initialValues = useMemo(() => {
    if (isEdit) {
      const { name, description, location, time: firestoreTime, capacity, waitlistEnabled, isPrivate, members } = event;
      const currentAttendees = mapToArrayWithIdKey('id', members).map((uRef) => {
        let { displayName, description, photoUrl } = uRef.user;
        const attendee: WithId<UserObjectReference> = { documentId: uRef.id, displayName, photoUrl };
        if (description != null) {
          attendee.description = description;
        }
        return attendee;
      });
      const { date, time } = splitDateAndTime(firestoreTime);
      return {
        name,
        description,
        location,
        capacity,
        waitlistEnabled,
        isPrivate,
        time,
        date,
        attendees: currentAttendees,
        eventImage: null as File | null,
      };
    }
    return {
      name: '',
      description: '',
      location: '',
      time: new Date(),
      date: new Date(),
      capacity: 20,
      waitlistEnabled: false,
      isPrivate: true as boolean | undefined,
      attendees: [] as WithId<UserObjectReference>[],
      eventImage: null as File | null,
    };
  }, [event, isEdit]);

  const form = useForm({
    initialValues,
    validateInputOnBlur: true,
    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      name: (v) => !v || v === '',
    },
  });

  return (
    <>
      <form
        data-test-id='event-form'
        onSubmit={form.onSubmit(
          async ({ attendees, time, date, name, description, location, waitlistEnabled, isPrivate, capacity, eventImage }) => {
            const dateTime = combineDateWithTime(date, time);

            let eventDoc: EventRecord = {
              name,
              description,
              location,
              waitlistEnabled,
              capacity,
              // Members are added through internal addEventDoc logic
              members: {},
              comments: {},
              time: Timestamp.fromDate(dateTime),
            };
            if (isPrivate != null) {
              eventDoc.isPrivate = isPrivate;
            }
            let eventId;
            if (isEdit) {
              eventId = event.documentId;
              const eventDocWithId = { documentId: event.documentId, ...eventDoc };
              await editEventDoc(firestore, login, eventDocWithId, attendees);

            } else {
              eventId = await addEventDoc(firestore, login, eventDoc, attendees);
            }
            if (eventImage != null) {
              uploadEventImage(firestore, eventId, eventImage);
            }
            onAdd(eventId);
          }
        )}
      >

        <TextInput
          data-test-id='name'
          mt='sm'
          withAsterisk
          label='Name'
          placeholder={`Your event's name`}
          {...form.getInputProps('name')}
        />
        <TextInput
          data-test-id='description'
          mt='sm'
          label='Description'
          placeholder='A short description of your event'
          {...form.getInputProps('description')}
        />
        <TextInput
          mt='sm'
          label='Location'
          placeholder='Type a location or provide a link'
          {...form.getInputProps('location')}
        />
        <FileInput
          placeholder="Pick file"
          label="Your resume"
          {...form.getInputProps('eventImage')}
        />
        <Input.Wrapper label='Attendees' mt='sm'>
          <TypedSelect<WithId<UserObjectReference>>
            options={suggestionsArray}
            renderLabel={(m) => {
              return (
                <div className='flex items-center gap-4'>
                  <CircledUserImageComponent size='40px' user={m} />
                  <div>{m.displayName}</div>
                </div>
              );
            }}
            getItemValue={(m) => m.documentId}
            {...form.getInputProps('attendees')}
            isDisabled={isEdit}
          />
        </Input.Wrapper>

        <DatePicker mt='sm' label='Date' {...form.getInputProps('date')} />

        <TimeInput mt='md' label='Time' {...form.getInputProps('time')} />

        <Checkbox mt='md' label='Enable waitlist' {...form.getInputProps('waitlistEnabled')} disabled={isEdit} />

        <Checkbox mt='sm' label='Private event' {...form.getInputProps('isPrivate')} />

        <NumberInput mt='sm' label='Capacity' {...form.getInputProps('capacity')} disabled={isEdit} />

        <Group position='right' mt='md'>
          <Button type='submit' bg='blue' data-test-id='submit'>
            Submit
          </Button>
        </Group>
      </form>
    </>
  );
}
