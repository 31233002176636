import { getStorage, ref, uploadBytes } from "firebase/storage";
import { createContext, useContext, useLayoutEffect, useMemo, useState } from 'react';

import { Analytics } from 'firebase/analytics';
import { addDoc, collection, CollectionReference, doc, Firestore, runTransaction } from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';

import { PlaceRecord, WithId } from './models/commons';
import {
  EventRecord,
  EventRole,
  makeEventSnippet,
  makeUserEventLink,
  mergeRoles,
  RsvpInfo,
  UserRefForEvent,
} from './models/event';
import {
  EventRefForUser,
  makeEventRefForUser,
  makeUserReferenceFromUserInfoRecord,
  UserInfoRecord,
  UserObjectReference,
} from './models/user';
import { NicerAuth } from './Shell/authentication/firebase-auth-helper';
import { UserInfoRecordWithId } from './Shell/authentication/firebase-user-info-context';

export const FirebaseContext = createContext<{
  firestore: Firestore;
  auth: NicerAuth;
  storage: FirebaseStorage;
  analytics: Analytics;
} | null>(null);

export function useFirestore() {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('FirebaseContext empty');
  return context.firestore;
}

export function useFirebaseAuth() {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('FirebaseContext empty');
  return context.auth;
}

export function useFirebaseAuthState() {
  const auth = useFirebaseAuth();
  const [state, setState] = useState(auth.state);
  useLayoutEffect(() => {
    return auth.onNicerStateChange((state1) => {
      setState(state1);
    });
  }, [auth]);
  return state;
}

export function useFirestoreCollection<T>(name: string) {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, name) as CollectionReference<T>, [firestore, name]);
}

export function useFirebaseStorage() {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('FirebaseContext empty');
  return context.storage;
}

export function useFirebaseAnalytics() {
  const context = useContext(FirebaseContext);
  if (!context) throw new Error('FirebaseContext empty');
  return context.analytics;
}

const usersCollectionKey = 'users';
export function getUsersCollection(firestore: Firestore) {
  return collection(firestore, usersCollectionKey) as CollectionReference<UserInfoRecord>;
}

export function useUserInfoCollection() {
  return useFirestoreCollection<UserInfoRecord>(usersCollectionKey);
}

const placesCollectionKey = 'places';
export function usePlacesCollection() {
  return useFirestoreCollection<PlaceRecord>(placesCollectionKey);
}

const eventsCollectionKey = 'events';
export function getEventsCollection(firestore: Firestore): CollectionReference<EventRecord> {
  return collection(firestore, eventsCollectionKey) as CollectionReference<EventRecord>;
}

export function useEventsCollection() {
  return useFirestoreCollection<EventRecord>(eventsCollectionKey);
}

export async function editEventDoc(
  firestore: Firestore,
  user: UserInfoRecordWithId,
  event: WithId<EventRecord>,
  additionalUsers: WithId<UserObjectReference>[]
) {
  const eventsCollection = getEventsCollection(firestore);
  return runTransaction(firestore, async (transaction) => {
    const { name, description, location, time, capacity, waitlistEnabled, isPrivate } = event;
    let eventDoc: any = {
      name,
      description,
      location,
      waitlistEnabled,
      capacity,
      time,
    };
    if (isPrivate != null) {
      eventDoc.isPrivate = isPrivate;
    }
    transaction.set(doc(eventsCollection, event.documentId), eventDoc, {
      merge: true,
    });
  });
}
/**
 * Adds an event to the events collection and adds an event snippet
 * to the groups collection
 * @param event The event to be added to the event collection and
 * referred to in groups
 */
export async function addEventDoc(
  firestore: Firestore,
  user: UserInfoRecordWithId,
  event: EventRecord,
  additionalUsers: WithId<UserObjectReference>[]
) {
  const eventsCollection = getEventsCollection(firestore);
  const usersCollection = getUsersCollection(firestore);
  // Don't need the Collection references here
  return runTransaction(firestore, async (transaction) => {
    // No matter what, we add the current user to the owners
    const newEvent = Object.assign({}, event);
    const userEventLink = makeUserEventLink([EventRole.Owner], { going: 'yes', extras: 0 });
    newEvent.members[user.documentId] = {
      user: { documentId: user.documentId, ...makeUserReferenceFromUserInfoRecord(user) },
      linkInfo: userEventLink,
    };
    // Add attendees
    const userEvLink = makeUserEventLink([EventRole.Member], { going: 'not-answered', extras: 0 });
    additionalUsers.forEach((u) => {
      newEvent.members[u.documentId] = {
        user: { ...u },
        linkInfo: userEvLink,
      };
    });

    // Create event
    const eventDocRef = await addDoc(eventsCollection, newEvent);

    // Update user's events
    const userRefForEvent = makeEventRefForUser({ documentId: eventDocRef.id, ...event }, userEventLink);
    const userUpdate = {
      events: {
        [eventDocRef.id]: userRefForEvent,
      },
    };
    transaction.set(doc(usersCollection, user.documentId), userUpdate, {
      merge: true,
    });

    additionalUsers.forEach((u) => {
      const userRefForEvent = makeEventRefForUser({ documentId: eventDocRef.id, ...event }, userEvLink);
      const userUpdate = {
        events: {
          [eventDocRef.id]: userRefForEvent,
        },
      };
      transaction.set(doc(usersCollection, u.documentId), userUpdate, {
        merge: true,
      });
    });
    return eventDocRef.id;
  });
}

export function eventRef(storage: FirebaseStorage, eventId: string) {
  return ref(storage, eventId + '/event-image.jpeg');
}

export async function uploadEventImage(firestore: Firestore, eventId: string, file: File) {

  const storage = getStorage();
  const storageRef = eventRef(storage, eventId);
  uploadBytes(storageRef, file).then((snapshot) => {
    console.log('Uploaded a event image');
  });
}

export async function addRsvp(
  firestore: Firestore,
  login: UserInfoRecordWithId,
  event: WithId<EventRecord>,
  rsvpInfo: RsvpInfo
) {
  return runTransaction(firestore, async (transaction) => {
    const eventDocRef = doc(getEventsCollection(firestore), event.documentId);

    const currentRsvp = event.members[login.documentId];
    const currentRoles = currentRsvp ? currentRsvp.linkInfo.permissions : [];
    const userEventLink = makeUserEventLink(mergeRoles(currentRoles, [EventRole.Member]), rsvpInfo);
    const newRsvp: UserRefForEvent = {
      user: { documentId: login.documentId, ...makeUserReferenceFromUserInfoRecord(login) },
      linkInfo: userEventLink,
    };
    const newEventForUser: EventRefForUser = { event: makeEventSnippet(event), linkInfo: userEventLink };
    const eventUpdate = { members: { [newRsvp.user.documentId]: newRsvp } };
    const userUpdate = { events: { [event.documentId]: newEventForUser } };
    transaction.set(eventDocRef, eventUpdate, { merge: true });
    transaction.set(doc(getUsersCollection(firestore), login.documentId), userUpdate, { merge: true });
  });
}
