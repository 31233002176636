import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import checkIcon from '@iconify-icons/ion/checkmark';
import linkIcon from '@iconify-icons/lucide/external-link';
import { Icon } from '@iconify/react';
import { GoogleAuthProvider, sendPasswordResetEmail, signInWithPopup } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router';
import { useFirebaseAuth } from '../../firebase';
import { StyledMainAsyncButton } from '../../UI/firebase-styles';
import { LinkLikeButton } from '../../UI/NavigationLinks';
import { StyledButton } from '../../UI/styles';
import { TypedTabs } from '../../UI/TypedTabs';
import { AsyncButtonBase } from '../../Utils/controls/AsyncButton';
import { FlexCenterColumn } from '../../Utils/controls/LayoutStyles';
import { SmallSpinner, Spinner } from '../../Utils/controls/SmallSpinner';
import { assertNever } from '../../Utils/typescript';
import { BaseRoute, LoginPageTab, LoginRoute } from '../RouteDefinitions';
import {
  createUserWithEmailAndPassword,
  createUserWithEmailAndPasswordError,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithEmailAndPasswordError,
} from './firebase-helpers';
import { GoToAppButtonComponent } from './GoToAppButtonComponent';
import { getDomainFromEmail, getNameFromEmail } from './utils';
import { Button, Card, Group, PasswordInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FirebaseUserInfoContext } from './firebase-user-info-context';

export function AuthenticationForm({ tab }: { tab?: LoginPageTab }) {
  const auth = useFirebaseAuth();
  const userInfoContext = useContext(FirebaseUserInfoContext);
  const loginState = userInfoContext.loginState;

  const [authMode, setAuthMode] = useState<LoginPageTab>('sign-in');
  const navigate = useNavigate();

  useEffect(() => {
    if (tab !== undefined) {
      setAuthMode(tab);
    }
  }, [tab]);

  if (loginState.state === 'loading' || loginState.state === 'getting-user-info') {
    return <Spinner />;
  }

  if (loginState.state === 'logged-in-unverified') {
    const user = loginState.firebaseUser;
    return (
      <FlexCenterColumn>
        <div>You are logged in as: {user.displayName ?? getNameFromEmail(user.email) ?? 'unknown'}.</div>
        <PendingEmailVerificationComponent />
      </FlexCenterColumn>
    );
  }

  if (loginState.state === 'logged-in') {
    return (
      <FlexCenterColumn>
        <div>You are logged in as: {loginState.userInfo.displayName}.</div>
        <GoToAppButtonComponent />
        <StyledMainAsyncButton onClickAsync={() => auth.signOut()}>Sign out</StyledMainAsyncButton>
      </FlexCenterColumn>
    );
  }

  return (
    <FlexCenterColumn className='p-4 self-stretch'>
      <Card className='self-stretch mx-auto min-w-min max-w-lg w-full'>
        <TypedTabs<LoginPageTab>
          selectedValue={authMode}
          allValues={['register', 'sign-in']}
          onSelectedValueChange={(e) => navigate(LoginRoute.makePath({ mode: 'login', tab: e }))}
          render={(tab) => {
            if (tab === 'register') {
              return {
                header: <div>Register</div>,
                tab: <RegisterFormComponent />,
              };
            } else if (tab === 'sign-in') {
              return {
                header: <div>Sign in</div>,
                tab: <SignInFormComponent />,
              };
            } else return assertNever(tab);
          }}
        />
      </Card>
      <div className='self-stretch flex items-center mx-auto w-full max-w-lg'>
        <div className='grow shrink h-px' style={{ backgroundColor: '#5a5a5a' }} />
        <div className='text-neutral-600 my-4 mx-2'>OR sign in with</div>
        <div className='grow shrink h-px' style={{ backgroundColor: '#5a5a5a' }}></div>
      </div>
      <AsyncButtonBase
        render={({ isLoading, onClick }) => (
          <Button
            size='lg'
            type='button'
            loading={isLoading}
            variant='outline'
            color='dark'
            leftIcon={<Icon icon='akar-icons:google-fill' width={24} height={24} />}
            onClick={onClick}
          >
            Google
          </Button>
        )}
        onClickAsync={async () => {
          const provider = new GoogleAuthProvider();
          await signInWithPopup(auth, provider);
        }}
      />
    </FlexCenterColumn>
  );
}

function PendingEmailVerificationComponent() {
  const auth = useFirebaseAuth();
  const currentUser = auth.currentUser;
  const [error, setError] = useState('');

  const location = useLocation();
  const st: any = location.state;
  let from = st?.from?.pathname;
  if (from === '/') from = undefined;
  return (
    <FlexCenterColumn>
      <div>An email confirmation has been sent.</div>
      <div>Please confirm your email before using the app.</div>
      {currentUser && (
        <AsyncButtonBase
          render={({ isLoading, onClick }) => {
            return (
              <StyledButton disabled={isLoading} onClick={onClick}>
                Resend
              </StyledButton>
            );
          }}
          onClickAsync={async () => {
            const result2 = await sendEmailVerification(currentUser, { url: from || null });
            if (result2.ok !== true) {
              setError('Failed to send verification email.');
            }
          }}
        />
      )}
      <AsyncButtonBase
        render={({ isLoading, onClick }) => {
          return (
            <StyledButton disabled={isLoading} onClick={onClick}>
              Use different email
            </StyledButton>
          );
        }}
        onClickAsync={async () => {
          await auth.signOut();
        }}
      />
      {error && <div className='text-red-400 text-xs'>{error}</div>}
    </FlexCenterColumn>
  );
}

function RegisterFormComponent() {
  const auth = useFirebaseAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validateInputOnBlur: true,

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (v) => (v.length > 6 ? null : 'Password is too short'),
      passwordConfirmation: (v, values) => (v === values.password ? null : 'Passwords do not match'),
    },
  });

  const register = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      setIsLoading(true);
      setError('');
      try {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        if (result.ok === true) {
          const result2 = await sendEmailVerification(result.result.user);
          if (result2.ok !== true) {
            setError('Failed to send verification email.');
          }
        } else if (result.ok === 'error-status-code') {
          setError(createUserWithEmailAndPasswordErrorText(result.code));
          return 'error';
        } else if (result.ok === undefined) {
          setError('Unknown error occurred');
          return 'error';
        } else {
          return assertNever(result);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [auth]
  );

  return (
    <>
      <form
        data-test-id='register'
        className='flex flex-col self-stretch'
        onSubmit={form.onSubmit((values) => register(values))}
      >
        <TextInput
          data-test-id='email'
          disabled={isLoading}
          mt='sm'
          withAsterisk
          label='Email'
          placeholder={`john.smith@gmail.com`}
          {...form.getInputProps('email')}
        />
        <TextInput
          data-test-id='password'
          disabled={isLoading}
          mt='sm'
          withAsterisk
          label='Password'
          {...form.getInputProps('password')}
        />

        <TextInput
          data-test-id='password-confirmation'
          disabled={isLoading}
          mt='sm'
          withAsterisk
          label='Password confirmation'
          {...form.getInputProps('passwordConfirmation')}
        />
        <Group position='center'>
          <Button data-test-id='register' disabled={!form.isValid() || isLoading} type='submit' mt='md'>
            Register
          </Button>
        </Group>
      </form>

      {error && <div className='text-red-400 text-xs'>{error}</div>}
    </>
  );
}

function createUserWithEmailAndPasswordErrorText(code: createUserWithEmailAndPasswordError): string {
  switch (code) {
    case 'auth/email-already-in-use':
      return 'Email is already used';
    case 'auth/invalid-email':
      return 'Email is invalid';
    case 'auth/operation-not-allowed':
      return 'Internal error. Please contact the administrator.';
    case 'auth/weak-password':
      return 'Password is too weak. It should be at least 6 characters';
  }
}

function signInWithEmailAndPasswordErrorText(code: signInWithEmailAndPasswordError): string {
  switch (code) {
    case 'auth/invalid-email':
      return 'Email is invalid.';
    case 'auth/user-disabled':
      return 'The user is disabled';
    case 'auth/user-not-found':
      return 'User not found.';
    case 'auth/wrong-password':
      return 'Password is incorrect.';
  }
}

function SignInFormComponent() {
  const [isLoading, setIsLoading] = useState(false);
  const auth = useFirebaseAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState<signInWithEmailAndPasswordError | undefined>();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validateInputOnBlur: true,

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (v) => (v.length > 1 ? null : 'Password is required'),
    },
  });

  const location = useLocation();
  const from = useMemo(() => {
    const st: any = location.state;
    return st?.from?.pathname;
  }, [location]);

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      setIsLoading(true);
      setErrorMessage('');
      setError(undefined);
      try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        if (result.ok === true) {
          navigate(from != null ? from : BaseRoute.makePath());
        } else if (result.ok === 'error-status-code') {
          setError(result.code);
          setErrorMessage(signInWithEmailAndPasswordErrorText(result.code));
          return 'error';
        } else if (result.ok === undefined) {
          setErrorMessage('Unknown error occurred');
          return 'error';
        } else {
          return assertNever(result);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [auth, from, navigate]
  );

  return (
    <>
      <form
        data-test-id='sign-in'
        className='flex flex-col self-stretch'
        onSubmit={form.onSubmit((values) => signIn(values))}
      >
        <TextInput
          data-test-id='email'
          disabled={isLoading}
          mt='sm'
          withAsterisk
          label='Email'
          placeholder={`john.smith@gmail.com`}
          {...form.getInputProps('email')}
        />
        <PasswordInput
          data-test-id='password'
          disabled={isLoading}
          mt='sm'
          withAsterisk
          label='Password'
          {...form.getInputProps('password')}
        />

        <Group position='right'>
          <Button data-test-id='sign-in' disabled={!form.isValid() || isLoading} type='submit' mt='md'>
            Sign in
          </Button>
        </Group>
      </form>
      {errorMessage && <div className='text-red-400 text-sm'>{errorMessage}</div>}
      {error === 'auth/user-not-found' && (
        <LinkLikeButton
          className='text-sm'
          onClick={() => navigate(LoginRoute.makePath({ mode: 'login', tab: 'register' }))}
        >
          Register
        </LinkLikeButton>
      )}
      {error === 'auth/wrong-password' && <WrongPasswordComponent email={form.values.email} />}
    </>
  );
}

function WrongPasswordComponent({ email }: { email: string }) {
  const auth = useFirebaseAuth();
  const [isSent, setIsSent] = useState(false);

  return (
    <div className='flex flex-col'>
      <AsyncButtonBase
        onClickAsync={async () => {
          await sendPasswordResetEmail(auth, email);
          setIsSent(true);
        }}
        render={({ isLoading, isSucceeded, onClick }) => {
          return (
            <LinkLikeButton
              onClick={() => {
                if (isLoading) return;
                onClick();
              }}
              className='text-sm py-2'
            >
              Send password reset link {isLoading && <SmallSpinner />} {isSucceeded && <Icon icon={checkIcon} />}
            </LinkLikeButton>
          );
        }}
      />
      {isSent && <CheckEmailComponent email={email} />}
    </div>
  );
}

export function CheckEmailComponent({ email }: { email: string }) {
  const domain = getDomainFromEmail(email);
  return (
    <div className='flex flex-col items-center'>
      <div>Password reset email sent</div>
      <a href={`https://${domain}`} className='flex items-center text-sm underline'>
        <div className='mr-1'>Check e-mail</div>
        <Icon icon={linkIcon} />
      </a>
    </div>
  );
}
