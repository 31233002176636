import { ReactNode } from 'react';
import Select, { MultiValue } from 'react-select';
import { getHashCode } from '../Utils/typescript';

export type FormFieledProps<T> = {
  label?: ReactNode;
  hints?: string[];
  errors?: (string | [boolean, string])[];
  disabled?: boolean;
  value: T;
  onChange: (v: T) => void;
};

export function FormField<T>({
  label,
  hints,
  errors,
  value,
  onChange,
  render,
}: FormFieledProps<T> & { render: (onChange: (v: T) => void, value: T) => ReactNode }) {
  function getErrorString(e: string | [boolean, string]) {
    if (typeof e === 'string') return e;
    return e[0] ? e[1] : null;
  }

  const errorStrings = errors?.map(getErrorString).filter((x): x is string => x !== null);
  return (
    <div className='flex flex-col mb-6'>
      <label className='flex flex-col items-stretch'>
        <div className='text-sm font-medium'>{label}</div>
        {render(onChange, value)}
      </label>
      <div className='h-4 flex-col'>
        {errorStrings?.map((x) => (
          <div key={getHashCode(x)} className='text-xs text-red-500'>
            {x}
          </div>
        ))}
        {!errors &&
          hints?.map((x) => (
            <div key={getHashCode(x)} className='text-xs text-red-500'>
              {x}
            </div>
          ))}
      </div>
    </div>
  );
}

export function TextFormField({
  type,
  placeholder,
  'data-test-id': dataTest,
  ...props
}: { type?: 'text' | 'email' | 'password'; placeholder?: string; 'data-test-id'?: string } & FormFieledProps<string>) {
  return (
    <FormField<string>
      {...props}
      render={(onChange, value) => (
        <input
          data-test-id={dataTest}
          className={
            'border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm' +
            ' focus:outline-none focus:border-indigo-700 placeholder-gray-500'
          }
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    />
  );
}

export function TextAreaFormField({
  type,
  placeholder,
  ...props
}: { type?: 'text' | 'email' | 'password'; placeholder?: string } & FormFieledProps<string>) {
  return (
    <FormField<string>
      {...props}
      render={(onChange, value) => (
        <textarea
          className={
            'border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm bg-transparent rounded text-sm' +
            ' focus:outline-none focus:border-indigo-700 placeholder-gray-500'
          }
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    />
  );
}

export function NumberInputField({
  value,
  label,
  onChange,
  increment,
  decrement,
}: {
  value: number;
  label: string;
  onChange: (v: number) => void;
  increment: () => void;
  decrement: () => void;
}) {
  return (
    <div className='custom-number-input h-10 w-50'>
      <label className='w-30 inline-block text-gray-700 text-sm font-semibold'>{label}</label>
      <div className='inline-block px-3 w-20'>
        <div className=' flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1'>
          <span onClick={decrement} className='m-auto text-2xl font-thin bg-gray-200 h-full w-20 rounded-l cursor-pointer hover:bg-gray-400'>−</span>
          <input
            onChange={(e) => onChange(e.target.valueAsNumber)}
            type='text'
            inputMode='numeric'
            className='outline-none text-center w-full bg-gray-300 font-semibold text-md h-full hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 '
            name='custom-input-number'
            value={value}
          ></input>
          <span onClick={increment} className='m-auto text-2xl font-thin bg-gray-200 h-full w-20 rounded-r cursor-pointer hover:bg-gray-400'>+</span>
        </div>
      </div>
    </div >
  );
}

const DateTimePicker = require('react-datetime-picker').default;

export function DateField({ onChange, value, ...props }: { label: string; onChange: (v: Date) => void; value: Date }) {
  return (
    <FormField<Date>
      {...props}
      value={value}
      onChange={onChange}
      render={(onChange, value) => <DateTimePicker onChange={onChange} value={value} />}
    />
  );
}

export function SelectField<TItem>({
  getItemValue,
  renderLabel,
  value,
  onChange,
  options,
  ...props
}: {
  options: TItem[];
  getItemValue: (item: TItem) => string;
  renderLabel: (item: TItem) => ReactNode;
} & FormFieledProps<TItem[]>) {
  return (
    <FormField<MultiValue<TItem>>
      {...props}
      value={value}
      onChange={(x) => onChange(x.map((y) => y))}
      render={(onChange, value) => {
        return (
          <Select
            getOptionValue={getItemValue}
            formatOptionLabel={renderLabel}
            options={options}
            value={value}
            onChange={(newValue) => onChange(newValue)}
            isMulti
          />
        );
      }}
    />
  );
}
