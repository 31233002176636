import { ReactNode } from 'react';
// multiselect from mantine is terrible:
// weird way to render item, no forcing open,
// no mention whatsoever about item values selectors
import Select from 'react-select';

export function TypedSelect<TItem>({
  getItemValue,
  renderLabel,
  value,
  onChange,
  options,
  isDisabled,
}: {
  options: TItem[];
  getItemValue: (item: TItem) => string;
  renderLabel: (item: TItem) => ReactNode;
  value: TItem;
  onChange: (v: TItem[]) => void;
  isDisabled?: boolean | undefined;
}) {
  return (
    <Select
      getOptionValue={getItemValue}
      formatOptionLabel={renderLabel}
      options={options}
      value={value}
      onChange={(newValue) => onChange(newValue.map((x) => x))}
      isMulti
      isDisabled={isDisabled}
    />
  );
}
