import React from 'react';
import styled from 'styled-components';
import {FlexColumn} from '../Utils/controls/LayoutStyles';

// type SliderStyleType = {height: string; width: string; position: 'vertical' | 'horizontal' | undefined};

const Label = styled.label`
  position: relative;
  display: inline-block;
  height: 23px;
  width: 49px;
  margin: 10px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + & {
    background-color: #2196f3;
  }

  input:focus + & {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + &:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  &.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
`;

export type SwitchPosition = 'left' | 'right';

export type SwitchProps = {
  left?: string;
  right?: string;
  value: SwitchPosition;
  onChanged: (newValue: SwitchPosition) => void;
};

function Switch({left, right, onChanged, value}: SwitchProps) {
  return (
    <>
      <FlexColumn>{left ?? ''}</FlexColumn>
      <FlexColumn>
        <Label>
          <input
            type='checkbox'
            onChange={() => onChanged(value === 'right' ? 'left' : 'right')}
            checked={value === 'right'}
          />
          <Slider className={`slider round`} />
        </Label>
      </FlexColumn>
      <FlexColumn>{right ?? ''}</FlexColumn>
    </>
  );
}

export default React.memo(Switch);
