export function ifDefined<T>(item: T | undefined, defaultValue: T): T {
  if (item === undefined) {
    return defaultValue;
  }
  return item;
}

export function ifNotNullAndDefined<T>(x: T | null | undefined, defaultValue: T): T {
  if (x === null || x === undefined) {
    return defaultValue;
  }
  return x;
}

export function ifNotNull<T>(item: T | null, defaultValue: T) {
  if (item === null) {
    return defaultValue;
  }
  return item;
}

export function assertNever<T>(_: never): T {
  throw new Error('should be unreachable.');
}

export function undefinedToNull<T>(t: T | undefined): T | null {
  if (t === undefined) {
    return null;
  }
  return t;
}

export function nullToUndefined<T>(t: T | null): T | undefined {
  if (t === null) {
    return undefined;
  }
  return t;
}

export type NonFunction = number | string | boolean | object | null | undefined;

export type ValueOrCallback<T extends NonFunction, TParams> = T | ((p: TParams) => T);

export function resolveValue<T extends NonFunction, TParams>(v: ValueOrCallback<T, TParams>, params: TParams) {
  if (typeof v === 'function') {
    return v(params);
  }
  return typeof v === 'function' ? v(params) : v;
}

export function nameof<T>(name: keyof T) {
  return name;
}

export function assertNonNullish<T>(x: T | null | undefined): T {
  if (x === null || x === undefined) {
    throw new Error();
  }
  return x;
}

export function nonNull(...strings: (string | undefined)[]): string[] {
  return strings.filter((x) => x !== undefined) as any;
}

export function getHashCode(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export function delay(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export type Nullish<T> = T | null | undefined;