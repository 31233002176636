import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {AppComponent} from './Shell/App';
import {versions} from './versions';

fetch(`${process.env.PUBLIC_URL}/environment.json`)
  .then((r) => r.json())
  .then((r) => {
    (window as any).environment = r;
    console.log(`starting app v.${versions.version}-${versions.revision}-${versions.branch}...`);
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

    root.render(<AppComponent />);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
