import { Timestamp } from '@firebase/firestore';

export function getNameFromEmail(email: string | null) {
  if (!email) {
    return null;
  }

  const atIndex = email.indexOf('@');
  if (atIndex > 0) {
    return email.substring(0, atIndex);
  }
  return email;
}

export function getDomainFromEmail(email: string) {
  const atIndex = email.indexOf('@');
  if (atIndex > 0) {
    return email.substring(atIndex + 1);
  }
  return null;
}

export function combineDateWithTime(d: Date, t: Date) {
  return new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    t.getHours(),
    t.getMinutes(),
    t.getSeconds(),
    t.getMilliseconds()
  );
}

export function splitDateAndTime(t: Timestamp) {
  const fullDateTime = t.toDate();
  return {
    date: new Date(fullDateTime.getFullYear(), fullDateTime.getMonth(), fullDateTime.getDate()),
    time: new Date(
      0,
      0,
      0,
      fullDateTime.getHours(),
      fullDateTime.getMinutes(),
      fullDateTime.getSeconds(),
      fullDateTime.getMilliseconds()
    ),
  };
}
