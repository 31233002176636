import { FirebaseApp } from 'firebase/app';
import { Auth, getAuth, User } from 'firebase/auth';
import { makeSubscribable } from '../../Utils/events/subscriptions';

export type AuthState =
  | { type: 'loading' }
  | {
      type: 'logged-in';
      logout(): void;
      user: User;
    }
  | { type: 'logged-out' };

export type AuthStateCb = (state: AuthState) => void;

export type NicerAuth = Auth & {
  state: AuthState;
  onNicerStateChange: (cb: AuthStateCb) => () => void;
};
// auth has horrible API. this gives a nicer workaround.
export function getNicerAuth(app: FirebaseApp): NicerAuth {
  const auth = getAuth(app);
  const anyAuth = auth as any;
  if (!anyAuth.onNicerStateChange) {
    const initial = { type: 'loading' } as const;
    const s = makeSubscribable<AuthState>(true, initial);
    let lastUser: User | null | undefined = undefined;
    auth.onAuthStateChanged(
      (value) => {
        if (value === lastUser) {
          return;
        }
        lastUser = value;

        const newState =
          value === null
            ? ({ type: 'logged-out' } as const)
            : ({
                type: 'logged-in',
                user: value,
                logout: () => auth.signOut(),
              } as const);
        anyAuth.state = newState;
        s.fire(newState);
      },
      (error) => {
        console.log(error);
      }
    );
    anyAuth.state = initial;
    anyAuth.onNicerStateChange = (cb: AuthStateCb) => {
      const sub = s.subscribable((data) => cb(data));
      return () => {
        sub.unsubscribe();
      };
    };
  }

  return anyAuth;
}
