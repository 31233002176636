import calendarDays20Solid from '@iconify-icons/heroicons/calendar-days-20-solid';
import mapPin20Solid from '@iconify-icons/heroicons/map-pin-20-solid';
import { Icon } from '@iconify/react';
import { doc, onSnapshot } from 'firebase/firestore';
import { getDownloadURL, getStorage } from "firebase/storage";
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import Linkify from 'react-linkify';
import { useNavigate } from 'react-router';
import { LoginRoute } from '../Shell/RouteDefinitions';
import { useOptionalUser } from '../Shell/authentication/UserInfoContext';
import { UserInfoRecordWithId } from '../Shell/authentication/firebase-user-info-context';
import { NumberInputField } from '../UI/FormField';
import Switch from '../UI/Switch';
import { AsyncButton } from '../Utils/controls/AsyncButton';
import { FlexCenterColumn } from '../Utils/controls/LayoutStyles';
import { useDialogApi } from '../Utils/controls/useDialogApi';
import { addRsvp, eventRef, useEventsCollection, useFirestore } from '../firebase';
import { WithId, mapToArray } from '../models/commons';
import { EventRecord, EventRole, GoingType, MAX_EVENT_CAPACITY } from '../models/event';
import { EditEventComponent } from './NewEventComponent';
import UserSnippetComponent from './UserSnippet';

export function MyEventLoginComponent({ id, nameHint }: { id: string; nameHint?: string }): ReactElement {
  return <MyEventComponent id={id} nameHint={nameHint} />;
}

export function MyEventComponent({ id, nameHint }: { id: string; nameHint?: string }) {
  const eventsCollection = useEventsCollection();
  const [event, setEvent] = useState<'loading' | 'error' | WithId<EventRecord>>('loading');

  useEffect(() => {
    return onSnapshot<EventRecord>(
      doc(eventsCollection, id),
      (snapshot) => {
        const data = snapshot.data();
        if (!data) {
          setEvent('error');
          return;
        }
        setEvent({ documentId: snapshot.id, ...data });
      },
      (error) => {
        console.error(error);
        setEvent('error');
      }
    );
  }, [id, eventsCollection]);

  if (event === 'loading') {
    return <FlexCenterColumn>{nameHint && <div>{nameHint}</div>}</FlexCenterColumn>;
  }
  if (event === 'error') {
    return <div>Error!</div>;
  }
  return <MyDefinedEventComponent id={id} event={event} />;
}

export function MyDefinedEventComponent({ id, event }: { id: string; event: WithId<EventRecord> }) {
  const login: UserInfoRecordWithId | null = useOptionalUser();
  const navigate = useNavigate();
  const storage = getStorage();

  const [imageURL, setImageURL] = useState<string | null>(null);
  const { managers, membersGoing, waitlist, notGoing, numAttendees, spacesLeft, amIManager } = useMemo(() => {
    const managers = mapToArray(event.members).filter((m) => m.linkInfo.permissions.includes(EventRole.Owner));
    const amIManager = login && managers.some((m) => m.id === login.documentId);
    const members = mapToArray(event.members).filter((m) => m.linkInfo.permissions.includes(EventRole.Member));
    const membersGoing = members.filter((m) => m.linkInfo.rsvp.going === 'yes' && !m.linkInfo.rsvp.isOnWaitlist);
    const waitlist = members.filter((m) => m.linkInfo.rsvp.isOnWaitlist);
    const notGoing = members.filter((m) => m.linkInfo.rsvp.going !== 'yes');
    const numAttendees = membersGoing.reduce((tot, current) => tot + 1 + current.linkInfo.rsvp.extras, 0);
    const rsvpInfo = login != null && event.members[login.documentId]
      ? event.members[login.documentId].linkInfo.rsvp
      : {
        going: 'no',
        extras: 0,
        isOnWaitlist: false,
      };
    const currentUserSpaces = rsvpInfo.going ? 1 + rsvpInfo.extras : 0;
    const capacity = event.capacity || MAX_EVENT_CAPACITY;
    const spacesLeft = capacity - numAttendees + currentUserSpaces;
    return { managers, membersGoing, waitlist, notGoing, numAttendees, spacesLeft, amIManager };
  }, [event, login]);

  const peopleSection = <React.Fragment>
    <div className='mt-2'>
      <h3 className='text-xl'>People</h3>
    </div>
    <div className='mx-auto max-w-7xl px-4 py-4 sm:px-4 lg:px-6'>
      <h4 className='text-l text-gray-400 text-left '>Managers</h4>
      {managers.map((x, i) => (
        <UserSnippetComponent key={i} user={x.user} />
      ))}
      <h4 className='text-l text-gray-400 text-left'>Attendees</h4>
      {membersGoing.map((x, i) => (
        <UserSnippetComponent key={i} user={x.user} rsvpInfo={x.linkInfo.rsvp} />
      ))}
      <h4 className='text-l text-gray-400 text-left'>Waitlist</h4>
      {waitlist.map((x, i) => (
        <UserSnippetComponent key={i} user={x.user} rsvpInfo={x.linkInfo.rsvp} />
      ))}
      <h4 className='text-l text-gray-400 text-left'>Not Going</h4>
      {notGoing.map((x, i) => (
        <UserSnippetComponent key={i} user={x.user} rsvpInfo={x.linkInfo.rsvp} />
      ))}
    </div>
  </React.Fragment>;

  const storageRef = eventRef(storage, id);
  useEffect(() => {
    async function updateDownloadURL() {
      const url = await getDownloadURL(storageRef);
      setImageURL(url);
    }
    updateDownloadURL();
  }, [storageRef, setImageURL]);
  return (
    <div data-test-id='event-info' className='pt-5 w-screen md:w-1/2'>
      <header className='bg-white shadow'>
        {imageURL &&
          <div>
            <img src={imageURL} alt='' className='w-full h-64 object-cover' />
          </div>}
        <div className='mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8 sm:flex'>
          <h1 className='text-3xl font-bold text-gray-900' data-test-id='name'>
            {event.name}
          </h1>
          <div className='align-center mx-auto my-auto'>
            {login && amIManager && <EditEventComponent event={event} login={login} membersSuggestions={{}} />}
          </div>
        </div>
      </header>
      <main>
        <div className='max-w-full mx-4 py-6 sm:mx-auto sm:px-6 lg:px-8'>
          <div className='flex flex-col justify-center items-center h-full'>
            <div className='text-2xl font-bold text-gray-900'>Event Details</div>
            <div className='flex flex-row justify-center bg-white rounded-lg align-bottom h-full w-full my-2'>
              <div className='mx-4 my-4'>
                <Icon icon={mapPin20Solid} />
              </div>
              <div className='inline-block text-l mx-4 font-bold text-center m-auto text-gray-900 sm:w-1/3'>
                <Linkify>{event.location}</Linkify>
              </div>
            </div>
            <div className='flex flex-row justify-center items-center bg-white rounded-lg align-bottom h-full w-full my-2'>
              <div className='mx-4 my-4'>
                <Icon icon={calendarDays20Solid} />
              </div>
              <div className='text-xl mx-4 my-4 font-bold text-gray-900 m-auto'>{event.time.toDate().toDateString()} {event.time.toDate().toLocaleTimeString()}</div>
            </div>
          </div>
          <div className='sm:flex sm:space-x-4'>
            <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-1'>
              {login ?
                <RSVPComponent login={login} event={event} spacesLeft={spacesLeft} />
                :
                <div className='w-full h-full py-[50%] text-xl text-center border-none hover:bg-sky-700 bg-white'
                  onClick={() => navigate(LoginRoute.makePath({ mode: 'login', tab: 'register' }))}>
                  Sign up to attend!
                </div>}
            </div>
            <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-1'>
              <div className='bg-white p-5'>
                <div className='sm:flex sm:items-start'>
                  <div className='text-center sm:mt-0 sm:ml-2 sm:text-left'>
                    <h3 className='text-sm leading-6 font-medium text-gray-400'>Attendees</h3>
                    <p className='text-3xl font-bold text-black'>{numAttendees}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-1'>
              <div className='bg-white p-5'>
                <div className='sm:flex sm:items-start'>
                  <div className='text-center sm:mt-0 sm:ml-2 sm:text-left'>
                    <h3 className='text-sm leading-6 font-medium text-gray-400'>Waitlist</h3>
                    <p className='text-3xl font-bold text-black'>{waitlist.length}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='max-w-full px-5 py-5 bg-white rounded-lg shadow'>
          <p data-test-id='description'><Linkify>{event.description}</Linkify></p>
        </div>
        {login && peopleSection}
      </main >
    </div >
  );
}

function RSVPComponent({
  login,
  event,
  spacesLeft,
}: {
  login: UserInfoRecordWithId;
  event: WithId<EventRecord>;
  spacesLeft: number;
}) {
  const dialog = useDialogApi();

  const rsvpInfo = event.members[login.documentId]
    ? event.members[login.documentId].linkInfo.rsvp
    : {
      going: 'no',
      extras: 0,
    };

  const goingText = rsvpInfo.going === 'yes' ? 'You\'re Going' : 'You\'re Not Going';

  return (
    <button
      onClick={() => {
        dialog.show<void>({
          render: ({ resolve }) => (
            <ConfirmRSVPComponent login={login} onAdd={resolve} event={event} spacesLeft={spacesLeft} />
          ),
          cancelResult: undefined,
          title: () => 'RSVP',
        });
      }}
      className='w-full h-full p-2 border-none hover:bg-sky-700 bg-white'
      disabled={spacesLeft === 0 && rsvpInfo.going !== 'yes' && !event.waitlistEnabled}
    >
      <div className='p-5 text-center text-xl'>{goingText} (Edit RSVP)</div>
    </button>
  );
}

function ConfirmRSVPComponent({
  login,
  onAdd,
  event,
  spacesLeft,
}: {
  login: UserInfoRecordWithId;
  onAdd: () => void;
  event: WithId<EventRecord>;
  spacesLeft: number;
}) {
  const firestore = useFirestore();
  const rsvpInfo = useMemo(
    () =>
      event.members[login.documentId]
        ? event.members[login.documentId].linkInfo.rsvp
        : {
          going: 'no',
          extras: 0,
          isOnWaitlist: false,
        },
    [login, event]
  );
  const [going, setGoing] = useState(rsvpInfo.going === 'yes');
  const [extras, setExtras] = useState(rsvpInfo.extras);
  const total = useMemo(() => (going ? 1 + extras : 0), [going, extras]);

  const increment = () => {
    if (spacesLeft - total > 0 || event.waitlistEnabled) {
      setExtras(extras + 1);
    }
  };
  const decrement = () => setExtras(Math.max(extras - 1, 0));
  return (
    <FlexCenterColumn>
      <div>
        <label className='w-full text-gray-700 text-sm font-semibold'>Are you coming?</label>
        <div className='inline-block'>
          <Switch
            value={going ? 'right' : 'left'}
            onChanged={(pos) => {
              setExtras(0);
              setGoing(pos === 'right');
            }}
          />
        </div>
        <p className='inline-block'>{going ? 'Yes' : 'No'}</p>
      </div>
      <NumberInputField
        label={'Are you bringing extras?'}
        increment={increment}
        decrement={decrement}
        onChange={setExtras}
        value={extras}
      />
      <div className='p-4 mt-2'>
        <AsyncButton
          onClickAsync={async () => {
            const rsvpInfo = {
              going: (going ? 'yes' : 'no') as GoingType,
              extras: extras,
              isOnWaitlist: total > spacesLeft,
            };
            await addRsvp(firestore, login, event, rsvpInfo);
            onAdd();
          }}
        >
          Confirm
        </AsyncButton>
      </div>
    </FlexCenterColumn>
  );
}
