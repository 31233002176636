import styled from 'styled-components';

export const StyledCard = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

export const contentPadding = '10px';

export const StyledButton = styled.button`
  cursor: pointer;
  border: 0;
  background: transparent;
  display: flex;
  height: 40px;

  align-items: center;
  padding: 10px 20px;

  :hover {
    background: #ebebeb;
    border-radius: 10px;
  }

  div {
    margin-left: 5px;
  }
`;
