import { Outlet } from 'react-router';
import { HeaderComponent } from './Header';

export default function Root() {
  return (
    <>
      <HeaderComponent />
      <Outlet />
    </>
  );
}

