import { Timestamp } from '@firebase/firestore';
import { CommentInfo, NestedMap, WithId } from './commons';
import { UserObjectReference } from './user';

export const MAX_EVENT_CAPACITY = 100;
export type LocationInfo = string;

export type EventSnippet = {
  name: string;
  description: string;
  location: LocationInfo;
  time: Timestamp;
  capacity: number;
  waitlistEnabled: boolean;
  isPrivate?: boolean; // Defaults to True
};

export type EventRecord = EventSnippet & {
  // Events do not necessarily belong to a group
  members: NestedMap<UserRefForEvent>;
  comments: NestedMap<CommentInfo>;
};

export function makeEventSnippet(event: WithId<EventRecord>): WithId<EventSnippet> {
  return {
    documentId: event.documentId,
    name: event.name,
    description: event.description,
    location: event.location,
    time: event.time,
    capacity: event.capacity,
    waitlistEnabled: event.waitlistEnabled,
    isPrivate: event.isPrivate != null ? event.isPrivate : false,
  };
}

export function makeUserEventLink(permissions: Array<EventRole>, rsvpInfo: RsvpInfo): EventUserLinkInfo {
  const dateCreated = new Date();
  return {
    dateCreated,
    permissions: permissions,
    rsvp: rsvpInfo,
  };
}

export function makeEventSnippetNoId(event: EventRecord): EventSnippet {
  return {
    name: event.name,
    description: event.description,
    location: event.location,
    time: event.time,
    capacity: event.capacity,
    waitlistEnabled: event.waitlistEnabled,
    isPrivate: event.isPrivate != null ? event.isPrivate : false,
  };
}

export type EventUserLinkInfo = {
  dateCreated: Date;
  permissions: Array<EventRole>;
  rsvp: RsvpInfo;
};

export function mergeRoles(existingRoles: Array<EventRole>, addRole: Array<EventRole>): Array<EventRole> {
  return Array.from(new Set<EventRole>(existingRoles.concat(addRole)));
}

export type GoingType = 'yes' | 'maybe' | 'no' | 'not-answered';

export enum EventRole {
  Owner = 'owner',
  Manager = 'manager',
  Member = 'member',
}

export type RsvpInfo = {
  going: GoingType;
  extras: number;
  isOnWaitlist?: boolean;
};

export type UserRefForEvent = {
  user: WithId<UserObjectReference>;
  linkInfo: EventUserLinkInfo;
};
