import { User } from 'firebase/auth';
import { getNameFromEmail } from '../Shell/authentication/utils';
import { NestedMap, WithId } from './commons';
import { EventRecord, EventSnippet, EventUserLinkInfo } from './event';

export type UserObjectReference = {
  displayName: string;
  description?: string;
  photoUrl: string | null | undefined;
};

export type UserInfoRecord = UserObjectReference & {
  firebaseAuthUserId: string;
  events: NestedMap<EventRefForUser>;
};

export function makeUserReference(user: User): UserObjectReference {
  return {
    displayName: user.displayName ?? getNameFromEmail(user.email) ?? 'unknown',
    photoUrl: user.photoURL,
  };
}

export function makeUserReferenceFromUserInfoRecord(user: UserInfoRecord): UserObjectReference {
  let v: UserObjectReference = {
    displayName: user.displayName,
    photoUrl: user.photoUrl,
  }
  if (user.description) { v.description = user.description; }
  return v;
}
export function makeEventRefForUser(event: WithId<EventRecord>, linkInfo: EventUserLinkInfo): EventRefForUser {
  return {
    event: event,
    linkInfo: linkInfo,
  };
}

export type EventRefForUser = {
  event: WithId<EventSnippet>;
  linkInfo: EventUserLinkInfo;
};

