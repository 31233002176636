import {
  confirmPasswordReset as confirmPasswordResetFirebase,
  createUserWithEmailAndPassword as createUserWithEmailAndPasswordFirebase,
  sendEmailVerification as sendEmailVerificationFirebase,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  verifyPasswordResetCode as verifyPasswordResetCodeFirebase,
} from 'firebase/auth';

function make<TFunc extends (...args: any[]) => any, TErrors extends readonly string[]>(
  fn: TFunc,
  errors: TErrors
): {
  fn: (
    ...params: Parameters<TFunc>
  ) => Promise<
    | { ok: true; result: Awaited<ReturnType<TFunc>> }
    | { ok: 'error-status-code'; code: TErrors[number]; message: string }
    | { ok: undefined; error: unknown }
  >;
  errors: TErrors;
} {
  return {
    fn: async (...params: Parameters<TFunc>) => {
      try {
        const user = await fn(...params);
        return { ok: true, result: user };
      } catch (e: any) {
        console.error(e);
        if (errors.find((x) => x === e.code)) {
          return { ok: 'error-status-code', code: e.code, message: e.message };
        }
        return { ok: undefined, error: e };
      }
    },
    errors,
  };
}

export const signInWithEmailAndPasswordErrors = [
  'auth/invalid-email',
  'auth/user-disabled',
  'auth/user-not-found',
  'auth/wrong-password',
] as const;
export type signInWithEmailAndPasswordError = typeof signInWithEmailAndPasswordErrors[number];

export const { fn: signInWithEmailAndPassword } = make(
  signInWithEmailAndPasswordFirebase,
  signInWithEmailAndPasswordErrors
);

export const createUserWithEmailAndPasswordErrors = [
  'auth/email-already-in-use',
  'auth/invalid-email',
  'auth/operation-not-allowed',
  'auth/weak-password',
] as const;
export type createUserWithEmailAndPasswordError = typeof createUserWithEmailAndPasswordErrors[number];
export const { fn: createUserWithEmailAndPassword } = make(
  createUserWithEmailAndPasswordFirebase,
  createUserWithEmailAndPasswordErrors
);

export const verifyPasswordResetCodeErrors = [
  'auth/expired-action-code',
  'auth/invalid-action-code',
  'auth/user-disabled',
  'auth/user-not-found',
] as const;
export type verifyPasswordResetCodeError = typeof verifyPasswordResetCodeErrors[number];
export const { fn: verifyPasswordResetCode } = make(verifyPasswordResetCodeFirebase, verifyPasswordResetCodeErrors);

export const confirmPasswordResetErrors = [
  'auth/expired-action-code',
  'auth/invalid-action-code',
  'auth/user-disabled',
  'auth/user-not-found',
  'auth/weak-password',
] as const;
export type confirmPasswordResetError = typeof confirmPasswordResetErrors[number];
export const { fn: confirmPasswordReset } = make(confirmPasswordResetFirebase, confirmPasswordResetErrors);

export const sendPasswordResetEmailErrors = [
  'auth/invalid-email',
  'auth/missing-android-pkg-name',
  'auth/missing-continue-uri',
  'auth/missing-ios-bundle-id',
  'auth/invalid-continue-uri',
  'auth/unauthorized-continue-uri',
  'auth/user-not-found',
] as const;
export type sendPasswordResetEmailError = typeof sendPasswordResetEmailErrors[number];
export const { fn: sendPasswordResetEmail } = make(sendPasswordResetEmailFirebase, sendPasswordResetEmailErrors);

export const sendEmailVerificationErrors = [
  'auth/missing-android-pkg-name',
  'auth/missing-continue-uri',
  'auth/missing-ios-bundle-id',
  'auth/invalid-continue-uri',
  'auth/unauthorized-continue-uri',
] as const;
export type sendEmailVerificationError = typeof sendEmailVerificationErrors[number];
export const { fn: sendEmailVerification } = make(sendEmailVerificationFirebase, sendEmailVerificationErrors);
